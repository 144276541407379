import { useEffect, useState } from "react";
import { createContext } from "react";
import { loadScript } from "../helpers/helper";

export const AppContext = createContext();
export default function AppContextProvider({ children }) {
  const [showLoader, setShowLoader] = useState(true);
  const [selecteState, setSelecteState] = useState("");
  const [isData, setIsData] = useState([]);
  const [dropLoaction, setDropLoaction] = useState("");
  const [searchHandle, setSearchHandle] = useState("");
  const [isDropData, setIsDropData] = useState([]);
  const [selectEvent, setSelectEvent] = useState([]);
  const [feature, setFeature] = useState("");
  const [selectEventGender, setSelectEventGender] = useState([]);
  const [minAge, setMinAge] = useState();
  const [maxAge, setMaxAge] = useState();
  const [selected, setSelected] = useState();
  const [selectedGradeTo, setSelectedGradeTo] = useState();

  const [pages, setPage] = useState(1);

  // useEffect(() => {
  //   loadScript(
  //     "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3571706419332962"
  //   );
  //   loadScript("", " (adsbygoogle = window.adsbygoogle || []).push({});");
  // }, []);

  return (
    <>
      <AppContext.Provider
        value={{
          setShowLoader,
          showLoader,
          setSelecteState,
          selecteState,
          isData,
          setIsData,
          pages,
          setPage,
          dropLoaction,
          setDropLoaction,
          setSearchHandle,
          searchHandle,
          isDropData,
          setIsDropData,
          selectEvent,
          setSelectEvent,
          setFeature,
          feature,
          setSelectEventGender,
          selectEventGender,
          minAge,
          setMinAge,
          maxAge,
          setMaxAge,
          selected,
          setSelected,
          selectedGradeTo,
          setSelectedGradeTo,
        }}
      >
        {children}
      </AppContext.Provider>
    </>
  );
}
