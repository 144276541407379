import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { TagsInput } from "react-tag-input-component";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
// day and time picker
import moment, { weekdays } from "moment";
// import { TagsInputCustom } from "../common-component/TagInput";
import { Modal } from "react-bootstrap";
import Spinner from "../common/Spinner";
import TextEditor from "../common/TextEditor";
import Auth from "../auth/Auth";
import { loadStripe } from "@stripe/stripe-js";
// import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

const stripePromise = loadStripe(`pk_test_wDlYbBsqPdfD9OfHS84AeZhS`);

// location picker
const initialState = {
  business_street_address: "",
};
function CreateEvent() {
  const ref = useRef();
  const eventImagesRef = useRef();
  const { id } = useParams();
  const dateRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const [createFormData, setCreateFormData] = useState(initialState);
  const [eventBanner, setEventBanner] = useState();
  const [image, setImage] = useState();
  const [eventImage, setEventImage] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedTag, setSelectedTag] = useState([]);
  const [tagValue, setTagValue] = useState("");
  const [weekDaysList, setWeekDaysList] = useState([]);
  const [isDateValid, setIsDateValid] = useState(false);
  const [DateValue, setDateValue] = useState(false);
  const [isWeekType, setIsWeekType] = useState();
  const locationRef = useRef();
  const [croperImage, setCroperImage] = useState("");
  const [packageRupees, setPackageRupees] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [showCardModal, setShowCardModal] = useState(false);

  // ------------ * image croper
  const [cropItem, setCropItem] = useState(null);
  const [showImageForCropModal, setShowImageForCropModal] = useState(false);
  const cropperRef = createRef();
  useEffect(() => {
    if (
      (createFormData?.weekend_day_hr_to <=
        createFormData?.weekend_day_hr_from &&
        !isEmpty(createFormData?.weekend_day_hr_to)) ||
      createFormData?.weekend_day_hr_to < createFormData?.weekend_day_hr_from ||
      (createFormData?.weekend_day_hr_to <=
        createFormData?.weekend_day_hr_from &&
        !isEmpty(createFormData?.weekend_day_hr_to)) ||
      createFormData?.weekend_day_hr_to < createFormData?.weekend_day_hr_from
    ) {
      setIsDateValid(true);
    }
  }, [createFormData]);
  // const shouldDisableDate = (date, state) => {
  //     // Disable end dates from start date
  //     return date <= new Date(state)
  // };
  // end location picker
  const {
    register,
    watch,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({ mode: "all" });
  // empty conditions
  const isEmpty = async (data) => {
    if (
      data == undefined ||
      data == null ||
      data == "" ||
      data == "undefined" ||
      data == "null"
    ) {
      return true;
    }
    return false;
  };
  const onSubmitForm = async (e, data) => {
    //============ form submit function
    // e.preventDefault()
    if (
      (watch()?.age_range_from?.length < 0 &&
        watch()?.age_range_from?.length > 100) ||
      parseInt(watch()?.age_range_from) > parseInt(watch()?.age_range_to)
    ) {
      return;
    }
    if (!watch()?.address) {
      return;
    }
    if (
      (!watch()?.week_days_hr_from && watch()?.week_days_hr_to) ||
      (watch()?.week_days_hr_from &&
        !watch()?.week_days_hr_to &&
        watch()?.week_days_hr_from > watch()?.week_days_hr_to)
    ) {
      return;
    }
    if (
      (!watch()?.weekend_day_hr_from && watch()?.weekend_day_hr_to) ||
      (watch()?.weekend_day_hr_from &&
        !watch()?.weekend_day_hr_to &&
        watch()?.weekend_day_hr_from > watch()?.weekend_day_hr_to)
    ) {
      return;
    }
    if (
      createFormData?.end_date_time &&
      createFormData?.start_date_time >= createFormData?.end_date_time
    ) {
      return;
    }
    // if (moment(createFormData?.start_date_time) >= moment(createFormData?.end_date_time)) {
    //     return
    // }
    if (tagValue > Number(25)) {
      return;
    }
    // if (moment(createFormData?.start_date_time)?.format("YYYY-MM-DD h:mm:ss") >= moment(createFormData?.end_date_time)?.format("YYYY-MM-DD h:mm")) {
    //     return
    // }
    setTimeout(() => {
      reset();
    }, 2000);
    setSpinner(true);
    let formData = new FormData();
    formData.append("event_title", watch()?.event_title);
    formData.append("event_desc", watch()?.event_desc);
    formData.append("address", watch()?.address);
    formData.append("purchase_url", watch()?.purchase_url);
    formData.append("social_instagram", watch()?.social_instagram);
    formData.append("social_facebook", watch()?.social_facebook);
    formData.append("social_twitter", watch()?.social_twitter);
    formData.append("social_linkedIn", watch()?.social_linkedIn);
    // formData.append("address", createFormData?.business_street_address);
    formData.append("category", watch()?.category);
    formData.append(
      "start_date_time",
      moment(createFormData?.start_date_time).format("YYYY-MM-DD H:mm")
    );
    // if (!createFormData?.end_date_time) {
    //     formData.append("", "");

    // } else {
    //     formData.append("end_date_time", moment(createFormData?.end_date_time).format("YYYY-MM-DD H:mm"));
    // }
    formData.append(
      "end_date_time",
      createFormData?.end_date_time
        ? moment(createFormData?.end_date_time).format("YYYY-MM-DD H:mm")
        : ""
    );

    // if (selectedDays?.length) {
    // formData.append("days_of_week", selectedDays?.map((a) => a?.title)?.join());
    formData.append(
      "days_of_week",
      selectedDays?.length ? selectedDays?.join() : ""
    );
    // }
    // ======Week days hours
    formData.append(
      "week_days_hr_from",
      watch()?.week_days_hr_from === undefined ? "" : watch()?.week_days_hr_from
    );
    formData.append(
      "week_days_hr_to",
      watch()?.week_days_hr_to === undefined ? "" : watch()?.week_days_hr_to
    );
    formData.append(
      "weekend_day_hr_from",
      watch()?.weekend_day_hr_from === undefined
        ? ""
        : watch()?.weekend_day_hr_from
    );
    formData.append(
      "weekend_day_hr_to",
      watch()?.weekend_day_hr_to === undefined ? "" : watch()?.weekend_day_hr_to
    );
    // ======School Grade
    formData.append(
      "school_grade_start",
      watch()?.school_grade_start ? Number(watch()?.school_grade_start) : ""
    );
    formData.append(
      "school_grade_end",
      watch()?.school_grade_end ? Number(watch()?.school_grade_end) : ""
    );
    // ======Age Range
    if (watch()?.all_age) {
      formData.append("all_age", watch()?.all_age ? 1 : 0);
    }
    formData.append(
      "age_range_from",
      watch()?.age_range_from ? watch()?.age_range_from : ""
    );

    formData.append("nightlife", watch()?.nightlife ? 1 : 0);

    formData.append(
      "age_range_to",
      watch()?.age_range_to ? watch()?.age_range_to : ""
    );
    // ======Gender
    formData.append("gender", watch()?.gender ? watch()?.gender : "");
    formData.append("tags", selectedTag?.join());
    // =====Contact deatils
    formData.append(
      "contact_name",
      watch()?.contact_name ? watch()?.contact_name : ""
    );
    formData.append("phone", watch()?.phone ? watch()?.phone : "");
    formData.append("email", watch()?.email ? watch()?.email : "");
    // =====Early Bird
    formData.append(
      "registration_fee",
      watch()?.registration_fee ? watch()?.registration_fee : ""
    );
    formData.append(
      "ticket_early_bird_price",
      watch()?.ticket_early_bird_price ? watch()?.ticket_early_bird_price : ""
    );
    formData.append(
      "ticket_early_bird_start_date_time",
      createFormData?.ticket_early_bird_start_date_time
        ? moment(createFormData?.ticket_early_bird_start_date_time).format(
            "YYYY-MM-DD"
          )
        : ""
    );
    formData.append(
      "ticket_early_bird_end_date_time",
      createFormData?.ticket_early_bird_end_date_time
        ? moment(createFormData?.ticket_early_bird_end_date_time).format(
            "YYYY-MM-DD"
          )
        : ""
    );
    // =====Semi- Late
    // if (watch()?.ticket_standard_price) {
    formData.append(
      "ticket_standard_price",
      watch()?.ticket_standard_price ? watch()?.ticket_standard_price : ""
    );
    // }
    formData.append(
      "ticket_standard_start_date_time",
      createFormData?.ticket_standard_start_date_time
        ? moment(createFormData?.ticket_standard_start_date_time).format(
            "YYYY-MM-DD"
          )
        : ""
    );
    formData.append(
      "ticket_standard_end_date_time",
      createFormData?.ticket_standard_end_date_time
        ? moment(createFormData?.ticket_standard_end_date_time).format(
            "YYYY-MM-DD"
          )
        : ""
    );
    formData.append(
      "ticket_standard_end_date_time",
      createFormData?.ticket_standard_end_date_time
        ? moment(createFormData?.ticket_standard_end_date_time).format(
            "YYYY-MM-DD"
          )
        : ""
    );
    // =====Very- Late
    formData.append(
      "ticket_late_price",
      watch()?.ticket_late_price ? watch()?.ticket_late_price : ""
    );
    // if (createFormData?.ticket_late_start_date_time) {
    formData.append(
      "ticket_late_start_date_time",
      createFormData?.ticket_late_start_date_time
        ? moment(createFormData?.ticket_late_start_date_time).format(
            "YYYY-MM-DD"
          )
        : ""
    );
    // }
    formData.append(
      "ticket_late_end_date_time",
      createFormData?.ticket_late_end_date_time
        ? moment(createFormData?.ticket_late_end_date_time).format("YYYY-MM-DD")
        : ""
    );

    // formData.append("status", watch()?.status);
    // ======featured events
    formData.append("featured", watch()?.featured ? 1 : 0);
    // ====== free event
    formData.append("is_free", watch()?.is_free ? 1 : 0);

    if (id && deletedImages?.length) {
      formData.append("event_pictures_deleted", deletedImages?.join());
    }

    if (typeof eventBanner === "object") {
      formData.append("banner", eventBanner);
    }

    // =====Event images
    if (eventImage?.length) {
      eventImage?.forEach((itm, i) => {
        if (typeof itm?.file === "object") {
          formData.append(`event_pictures[${i}]`, itm?.file);
        }
      });
    }
    // create event post api
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}users/event-data`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${Auth?.token()}`,
        },
        method: "POST",
        body: formData,
      }
    );
    if (response?.status === 200) {
      const data = await response.json();
      if (watch("featured") === true) {
        __packageEvent(data?.data?.id);
      }
      setSelectedDays([]);
      setSelectedTag([]);
      setEventBanner();
      setEventImage([]);
      setTimeout(() => {
        setSpinner(false);
      }, 500);
      navigate("/");
      toast.success(
        `${id ? "Event Updated successfully" : "Event created successfully"}`
      );
    }
    if (response?.status === 401) {
      setSpinner(true);
      localStorage.clear();
      toast.success(`Session expired !`);
    }
    if (response.status >= 400 && response.status <= 500) {
      let error = await response.json();
      toast.error(error?.message);
      setSpinner(false);
    }
  };
  const __packageEvent = async (id) => {
    setSpinner(true);
    const requestOption = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Auth.token()}`,
      },
    };

    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}users/event-data/${id}/requestFeatured?${packageRupees}=1`,
      requestOption
    );

    if (res.status >= 200 && res.status <= 399) {
      const data = await res.json();
      if (data?.data?.client_secret) {
        setClientSecret(data?.data?.client_secret);
        setShowCardModal(true);
      }
      //   toast.success(data?.data?.message, { autoClose: 2000 });
      setTimeout(() => {
        setSpinner(false);
      }, 1000);
    }
    if (res.status === 401) {
      localStorage.removeItem("__WTD_WEB__");
      //   navigate("/");
    }
    if (res.status >= 400 && res.status <= 500) {
      const error = await res.json();
      toast.error(error?.message);
      setSpinner(false);
    }
  };

  const options = {
    clientSecret: clientSecret,
  };

  const CheckoutForm = () => {
    const stripe = useStripe();
    const [errorMessage, setErrorMessage] = useState(null);

    // // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 5
    const elements = stripe?.elements(options);

    const paymentElement = elements?.create("payment");
    paymentElement?.mount("#payment-element");

    if (typeof document !== "undefined") {
      const form = document?.getElementById("payment-form");

      form?.addEventListener("submit", async (event) => {
        event.preventDefault();

        const { error, paymentIntent } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: `${window.location?.origin}`,
          },
          redirect: "if_required",
        });
        console.log(error);
        console.log(paymentIntent);

        if (error) {
          console.error(error);
          // handleError();
        } else if (paymentIntent) {
          console.log("Payment succeeded");
          setShowCardModal(false);
          navigate("/");
          // handleSuccess();
        } else {
          console.log("Payment failed");
          // handleOther();
        }
      });
    }

    return (
      <form id="payment-form">
        {/* <PaymentElement /> */}
        <div id="payment-element"></div>
        <button
          type="submit"
          className="primery-btn selected modalPay"
          id="submit"
          disabled={!stripe || !elements}
        >
          Pay
        </button>
        {/* Show error message to your customers */}
        {errorMessage && <div>{errorMessage}</div>}
      </form>
    );
  };
  //    =================== Get  Event Category api
  const [eventCategoryListingData, setEventCategoryListingData] = useState([]);
  const categoryListing = async (search, pages) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}event-category?paginate=0&sortBy=name`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
        method: "GET",
      }
    );
    setSpinner(true);
    if (response?.status === 200) {
      const data = await response.json();
      setEventCategoryListingData(data?.data);

      setSpinner(false);
    }
    // for error msgs
    else {
      const data = await response.json();
    }
  };
  useEffect(() => {
    categoryListing();
  }, []);
  // gender
  const gender = [
    {
      value: 1,
      title: "Male",
    },
    {
      value: 2,
      title: "Female",
    },
    {
      value: 3,
      title: "Co-ed",
    },
    {
      value: 4,
      title: "Anyone",
    },
  ];
  // School Grade
  const school_grade_list = [
    {
      value: 1,
      title: "Pre-K",
    },
    {
      value: 2,
      title: "Kindergarten",
    },
    {
      value: 3,
      title: "1st Grade",
    },
    {
      value: 4,
      title: "2nd Grade",
    },
    {
      value: 5,
      title: "3rd Grade",
    },
    {
      value: 6,
      title: "4th Grade",
    },
    {
      value: 7,
      title: "5th Grade",
    },
    {
      value: 8,
      title: "6th Grade",
    },
    {
      value: 9,
      title: "7th Grade",
    },
    {
      value: 10,
      title: "8th Grade",
    },
    {
      value: 11,
      title: "9th Grade",
    },
    {
      value: 12,
      title: "10th Grade",
    },
    {
      value: 13,
      title: "11th Grade",
    },
    {
      value: 14,
      title: "12th Grade",
    },
  ];
  const handleChangeDateAndTime = (data, name) => {
    let t = moment(data).format("yyyy-DD-mm HH:MM")
    setDateValue(true)
    setCreateFormData((prevState) => ({
        ...prevState,
        [name]: data,
    }));
    // if (
    //     (id || createFormData?.start_date_time, createFormData?.end_date_time)
    // ) {
    //     setSelectedDays([]);
    //     clearData("week_days_hr_from")
    // }

};   
 const handleChangeStartEndDateAndTime = (data, name) => {
    let t = moment(data).format("yyyy-DD-mm HH:MM")
    setDateValue(true)
    setCreateFormData((prevState) => ({
        ...prevState,
        [name]: data,
    }));
    if (
        (id || createFormData?.start_date_time, createFormData?.end_date_time)
    ) {
        setSelectedDays([]);
        clearData("week_days_hr_from")
    }

};
  // location picker
  // --------------------------------- temp comment ----------------------
  const handleChangeAddress = (data) => {
    setCreateFormData((prevState) => ({
      ...prevState,
      business_street_address: data.location,
    }));
  };
  // =================== event multi images selector
  const handleImage = (e) => {
    // const data = e.target.files;
    // let totalPhotosLength = data.length + eventImage?.length;

    let totalPhotosLength = eventImage?.length;

    if (totalPhotosLength > 4) {
      toast.error("Only 4 images are accepted.");
      return;
    }
    // for (let i of e.target.files) {

    if (e?.size > 1e6) {
      // toast.error("Image size too large,upload upto 1MB.", {
      //   autoClose: 3000,
      // });
      return;
    } else {
      // debugger
      setEventImage((prev) => {
        return [...prev, { file: e }];
      });
    }
    // }
  };
  useEffect(() => {
    if (croperImage === "1") setShowImageForCropModal(true);
  }, [croperImage === "1"]);
  // ========remove multi event images  cross btn
  const [deletedImages, setDeletedImages] = useState([]);
  const onRemoveAttachment = (elem) => {
    let attachedFileList = eventImage?.filter((item) => item !== elem);
    setEventImage(attachedFileList);
    setDeletedImages((prev) => [...prev, elem]);
  };
  const dateFormat = (time) => {
    let date = new Date();
    let t = time?.split(":");
    date.setHours(t[0]);
    date.setMinutes(t[1]);
    date.setSeconds(t[2]);
    return date;
  };
  // ====================== edit/get category
  useEffect(() => {
    if (location?.state && id) {
      // --------------------------------- temp comment ----------------------
      // locationRef.current.value = location?.state?.address
      // setValue("address", location?.state?.business_street_address)
      setValue("featured", location?.state?.featured);
      setValue("event_title", location?.state?.event_title);
      setValue("address", location?.state?.address);
      setValue(
        "event_desc",
        location?.state?.event_desc === null ? "" : location?.state?.event_desc
      );
      setValue("category", location.state.category.id);
      setValue("purchase_url", location?.state?.purchase_url);

      setValue(
        "social_instagram",
        location?.state?.social_instagram === null
          ? ""
          : location?.state?.social_instagram
      );
      setValue(
        "social_facebook",
        location?.state?.social_facebook === null
          ? ""
          : location?.state?.social_facebook
      );
      setValue(
        "social_twitter",
        location?.state?.social_twitter === null
          ? ""
          : location?.state?.social_twitter
      );
      setValue(
        "social_linkedIn",
        location?.state?.social_linkedIn === null
          ? ""
          : location?.state?.social_linkedIn
      );
      // ======Week days hours
      setValue(
        "week_days_hr_to",
        location.state.week_days_hr_to ? location.state.week_days_hr_to : ""
      );
      setValue(
        "week_days_hr_from",
        location?.state?.week_days_hr_from
          ? location?.state?.week_days_hr_from
          : ""
      );
      setValue(
        "weekend_day_hr_to",
        location.state.weekend_day_hr_to ? location.state.weekend_day_hr_to : ""
      );
      setValue(
        "weekend_day_hr_from",
        location?.state?.weekend_day_hr_from
          ? location?.state?.weekend_day_hr_from
          : ""
      );
      // ===================== start time
      setCreateFormData((prev) => ({
        ...prev,
        start_date_time: location?.state?.start_date_time,
        business_street_address: location?.state?.address,
      }));
      // ===================== end time
      setCreateFormData((prev) => ({
        ...prev,
        end_date_time: location?.state?.end_date_time,
      }));
      // ===================== end time
      setSelectedDays(
        location?.state?.days_of_week
          ? location?.state?.days_of_week?.split(",")
          : []
      );
      // ===================== ages
      setValue("all_age", location?.state?.all_age);
      setValue("nightlife", location?.state?.nightlife);
      setValue("age_range_from", location?.state?.age_range_from);
      setValue("age_range_to", location?.state?.age_range_to);
      setValue("gender", location?.state?.gender);
      setSelectedTag(
        location.state.tags ? location.state.tags?.split(",") : []
      );
      setValue("school_grade_start", location?.state?.school_grade_start);
      setValue("school_grade_end", location?.state?.school_grade_end);
      setValue("is_free", location?.state?.is_free);
      // ===================== early_bird ticket price
      setValue("registration_fee", location?.state?.registration_fee);
      setValue(
        "ticket_early_bird_price",
        location?.state?.ticket_early_bird_price
      );
      setCreateFormData((prev) => ({
        ...prev,
        ticket_early_bird_start_date_time:
          location?.state?.ticket_early_bird_start_date_time,
      }));
      setCreateFormData((prev) => ({
        ...prev,
        ticket_early_bird_end_date_time:
          location?.state?.ticket_early_bird_end_date_time,
      }));
      // =====================standard ticket price
      setValue("ticket_standard_price", location?.state?.ticket_standard_price);
      setCreateFormData((prev) => ({
        ...prev,
        ticket_standard_start_date_time:
          location?.state?.ticket_standard_start_date_time,
      }));
      setCreateFormData((prev) => ({
        ...prev,
        ticket_standard_end_date_time:
          location?.state?.ticket_standard_end_date_time,
      }));
      // ===================== very late ticket price
      setValue("ticket_late_price", location?.state?.ticket_late_price);
      setCreateFormData((prev) => ({
        ...prev,
        ticket_late_start_date_time:
          location?.state?.ticket_late_start_date_time,
      }));
      setCreateFormData((prev) => ({
        ...prev,
        ticket_late_end_date_time: location?.state?.ticket_late_end_date_time,
      }));
      // ===================== contact details
      setValue("contact_name", location?.state?.contact_name);
      setValue("phone", location?.state?.phone);
      setValue("email", location?.state?.email);
      // // ===================== images
      setEventBanner(
        location?.state?.banner
          ? `${process.env.REACT_APP_MEDIA_URL}/${location?.state?.banner}`
          : ""
      );
      setEventImage(location.state.event_pictures);
    }
    //eslint-disable-next-line
  }, [location?.state, id]);
  useEffect(() => {
    if (watch()?.is_free) {
      setValue("ticket_early_bird_price", "");
      setValue("ticket_early_bird_start_date_time", "");
      setValue("ticket_early_bird_end_date_time", "");

      setValue("ticket_standard_price", "");
      setValue("ticket_standard_start_date_time", "");
      setValue("ticket_standard_end_date_time", "");

      setValue("ticket_late_price", "");
      setValue("ticket_late_start_date_time", "");
      setValue("ticket_late_end_date_time", "");

      setCreateFormData((prev) => ({
        ...prev,
        ticket_early_bird_start_date_time: "",
        ticket_early_bird_end_date_time: "",
        ticket_standard_start_date_time: "",
        ticket_standard_end_date_time: "",
        ticket_late_start_date_time: "",
        ticket_late_end_date_time: "",
      }));
    }
  }, [watch()?.is_free]);

  // age ranges empty states when free is true
  useEffect(() => {
    if (watch()?.all_age) {
      setValue("age_range_from", "");
      setValue("age_range_to", "");
    }
  }, [watch()?.all_age]);
  // =============================================== week days show according to the start date and end date
  const daysBetweenDates = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    // Create an array of day names
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    // Iterate over each day between the start and end dates
    const dayStrings = [];
    let currentDate = new Date(startDate);
    if (start && end) {
      while (currentDate?.getDate() <= endDate?.getDate()) {
        const dayOfWeek = currentDate.getDay();
        const dayName = days[dayOfWeek];
        dayStrings.push(dayName);
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }

    const newDaysArray = [...new Set(dayStrings)];
    setWeekDaysList(newDaysArray);
    if (start && !end) {
      setWeekDaysList([days[currentDate?.getDay()]]);
    }

    if (newDaysArray.length === 0) {
      return;
    }
    if (
      newDaysArray.length === 2 &&
      newDaysArray.includes("Sunday") &&
      newDaysArray.includes("Saturday")
    ) {
      setIsWeekType("weekEnd_only");
    } else if (
      newDaysArray.includes("Sunday") ||
      newDaysArray.includes("Saturday")
    ) {
      setIsWeekType("weekDays_both");
    } else {
      setIsWeekType("weekDays_only");
    }
    // return dayStrings
  };
  useEffect(() => {
    if (createFormData?.start_date_time || createFormData?.end_date_time) {
      daysBetweenDates(
        createFormData?.start_date_time,
        createFormData?.end_date_time
      );
    }
  }, [createFormData?.start_date_time, createFormData?.end_date_time]);
  // ================================ empty data with cross btn
  const clearData = (state) => {
    setCreateFormData((prev) => ({
      ...prev,
      ...(state === "start_date_time" ? { start_date_time: "" } : ""),
      ...(state === "end_date_time" ? { end_date_time: "" } : ""),
      ...(state === "ticket_early_bird_start_date_time"
        ? { ticket_early_bird_start_date_time: "" }
        : ""),
      ...(state === "ticket_early_bird_end_date_time"
        ? { ticket_early_bird_end_date_time: "" }
        : ""),
      ...(state === "ticket_standard_start_date_time"
        ? { ticket_standard_start_date_time: "" }
        : ""),
      ...(state === "ticket_standard_end_date_time"
        ? { ticket_standard_end_date_time: "" }
        : ""),
      ...(state === "ticket_late_start_date_time"
        ? { ticket_late_start_date_time: "" }
        : ""),
      ...(state === "ticket_late_end_date_time"
        ? { ticket_late_end_date_time: "" }
        : ""),
    }));
    if (state === "week_days_hr_from") {
      setValue("week_days_hr_from", "");
    }
    if (state === "week_days_hr_to") {
      setValue("week_days_hr_to", "");
    }
    if (state === "weekend_day_hr_from") {
      setValue("weekend_day_hr_from", "");
    }
    if (state === "weekend_day_hr_to") {
      setValue("weekend_day_hr_to", "");
    }
  };
  const urlPattern =
    /^(http(s)?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z0-9]{2,}(\S*)?$/;
  // ============= text-editor
  // ------------ * image croper
  // -- *croper funntion -
  function base64ToImageFile(base64String, fileName) {
    const byteCharacters = atob(base64String?.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers); // create Uint8Array from byte array
    const blob = new Blob([byteArray], { type: "image/png" }); // create Blob from Uint8Array
    const file = new File([blob], fileName, { type: "image/png" }); // create File from Blob and file name
    return file;
  }
  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      if (croperImage === "0") {
        setEventBanner(
          base64ToImageFile(
            cropperRef.current?.cropper.getCroppedCanvas().toDataURL(),
            "croppedImage"
          )
        );
        setCroperImage("");
      } else {
        handleImage(
          base64ToImageFile(
            cropperRef.current?.cropper.getCroppedCanvas().toDataURL(),
            "croppedImage"
          )
        );
        setCroperImage("");
      }
    }
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setCropItem(reader?.result);
      });

      reader.readAsDataURL(e.target.files[0]);
    }
  };
  useEffect(() => {
    if (image?.name) {
      setShowImageForCropModal(true);
    }
  }, [image]);

  return (
    <>
      {spinner && <Spinner />}
      <div className="content">
        <div className="bk mb-3">
          <button
            onClick={() => navigate("/")}
            className="bg-transparent border-0"
          >
            <img src="assets/images/arrow_back.svg" alt="back arrow" />
          </button>
          <div className="row align-items-center">
            <div className="col-7">
              {" "}
              <h1 className="head1 mb-0 formH">
                {id ? "Edit" : "Create"} Event{" "}
              </h1>
            </div>
            <div className="col-5 event-toggle-btn">
              <div className="form-check form-switch text-end ps-0">
                <input
                  className="form-check-input  float-none  me-3"
                  {...register("featured")}
                  type="checkbox"
                  role="switch"
                  id="featuredEvent"
                />
                <label
                  className="form-check-label mb-0"
                  htmlFor="featuredEvent1"
                >
                  Featured
                </label>
              </div>
              <div className="radiobtnCon_g d-flex align-items-center">
                <div className="radiobtn_item me-3">
                  <input
                    type="radio"
                    name="datew"
                    className="rdinp"
                    value={packageRupees}
                    disabled={watch("featured") === false}
                    id="rdinp1"
                    onChange={() => setPackageRupees("weeklyFeature")}
                  />
                  <label
                    className={`${
                      watch("featured") === false
                        ? "rbtnev1 mb-0"
                        : "rbtnev mb-0"
                    }`}
                    htmlFor="rdinp1"
                  >
                    <b>$50</b> Weekly
                  </label>
                  {watch("featured") === true && (
                    <img
                      src="assets/images/rdchecked_icon.svg"
                      alt="rdchecked icon"
                      className="rdchecked_icon"
                    />
                  )}
                </div>
                <div className="radiobtn_item">
                  <input
                    type="radio"
                    name="datew"
                    className="rdinp"
                    value={packageRupees}
                    disabled={watch("featured") === false}
                    id="rdinp2"
                    onChange={() => setPackageRupees("monthlyFeature")}
                  />
                  <label
                    className={`${
                      watch("featured") === false
                        ? "rbtnev1 mb-0"
                        : "rbtnev mb-0"
                    }`}
                    htmlFor="rdinp2"
                  >
                    <b>$150</b>Monthly
                  </label>
                  {watch("featured") === true && (
                    <img
                      src="assets/images/rdchecked_icon.svg"
                      alt="rdchecked icon"
                      className="rdchecked_icon"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper_content">
          <div className="form_content my-4">
            <form onSubmit={handleSubmit(onSubmitForm)}>
              <div className="row">
                <div className="col-sm-6 padd-right">
                  <div className="input_group mb-4">
                    <label htmlFor="">
                      Event Title<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      className={`input_control ${
                        errors.event_title?.type === "required" ? "require" : ""
                      }`}
                      {...register("event_title", { required: true })}
                      placeholder="Event Title"
                      aria-invalid={errors.event_title ? "true" : "false"}
                      maxLength={200}
                    />
                    {errors.event_title?.type === "required" && (
                      <p role="alert" className="alert-msg">
                        Event Title is required
                      </p>
                    )}
                  </div>

                  <div className="input_group mb-4">
                    <label htmlFor="">
                      Description
                      <span className="subLabel">
                        {/* ( {watch()?.event_desc?.length ? { 5000 - watch()?.event_desc?.length } "characters are left":5000 - watch()?.event_desc?.length} characters are left) */}
                        (
                        {watch()?.event_desc === ""
                          ? "Max limit is 5000 characters"
                          : 5000 -
                            watch()?.event_desc?.length +
                            " characters are left"}
                        )
                      </span>
                    </label>
                    {/* <textarea
                                            className="textarea"
                                            placeholder="Enter Description"
                                            {...register("event_desc")}
                                            maxLength={5000}
                                        ></textarea> */}
                    <Controller
                      name="event_desc"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextEditor
                          data={field.value}
                          className="textarea"
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            field.onChange(data);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="input_group mb-4">
                    <label htmlFor="">
                      Event location (address or location)<sup>*</sup>
                    </label>

                    <input
                      type="text"
                      className={`input_control location-in_icon ${
                        errors.address?.type === "required" ? "require" : ""
                      }`}
                      {...register("address", { required: true })}
                      placeholder="Enter event address or name"
                      aria-invalid={errors.address ? "true" : "false"}
                    />
                    {errors.address?.type === "required" && (
                      <p role="alert" className="alert-msg">
                        Event Address is required
                      </p>
                    )}
                    {/* google location picker ========================== */}
                    {/* <AddressSearch
                                            className={` ${Object.values(errors)?.length > 0 &&
                                                !locationRef?.current?.value
                                                ? "require"
                                                : ""
                                                }`}
                                            location={createFormData?.business_street_address}
                                            handleChange={handleChangeAddress}
                                            locationRef={locationRef}
                                        />

                                        {Object.values(errors)?.length > 0 &&
                                            !locationRef?.current?.value && (
                                                <p role="alert" className="alert-msg">
                                                    Event Location is required
                                                </p>
                                            )} */}
                  </div>
                  <div className="input_group mb-4">
                    <label htmlFor="">
                      Event Category<sup>*</sup>
                    </label>
                    <select
                      className={` select_control select_icon-arrow ${
                        errors.category?.type === "required" ? "require" : ""
                      }`}
                      {...register("category", { required: true })}
                      value={watch()?.category}
                    >
                      <option value="" className="option">
                        Select category
                      </option>
                      {eventCategoryListingData?.map((item, index) => {
                        return (
                          <option value={item.id} key={index + 1}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    {errors.category?.type === "required" && (
                      <p role="alert" className="alert-msg">
                        Event Category is required
                      </p>
                    )}
                  </div>

                  <div className="row px-0 mb-4">
                    <div className="col-12">
                      <div className="d-flex align-items-center custom-checkbox nightlifeCheck">
                        <input
                          type="checkbox"
                          className="me-2 checkbox"
                          id="ageset"
                          {...register("nightlife")}
                        />
                        <label htmlFor="ageset" className="mb-0">
                          Parties and Nightlife
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="input_group mb-4 date_time_input  day-time">
                    <label htmlFor="">
                      Start Date & Time<sup>*</sup>
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDateTimePicker
                        value={
                          createFormData?.start_date_time
                            ? dayjs(createFormData?.start_date_time)
                            : ""
                        }
                        className={` input_control cald_icon  start-date ${
                          createFormData?.start_date_time
                            ? "avtive-input"
                            : " label-input label-input-date"
                        }
                        ${
                          errors.start_date_time?.type === "required" &&
                          watch()?.start_date_time !== undefined &&
                          watch()?.start_date_time !== "Invalid date"
                            ? "require"
                            : ""
                        }`}
                        onChange={(val) => {
                          handleChangeStartEndDateAndTime(
                            new Date(val),
                            "start_date_time"
                          );
                        }}
                        disablePast
                        inputRef={dateRef}
                      />
                      {createFormData?.start_date_time ? (
                        <div className="clear-data remove-btn pointer">
                          <img
                            src="assets/images/remove.png"
                            onClick={() => clearData("start_date_time")}
                            alt="clear"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </LocalizationProvider>
                    {Object.values(errors)?.length > 0 &&
                      !createFormData?.start_date_time && (
                        <p role="alert" className="alert-msg">
                          Start Date & Time is required
                        </p>
                      )}
                  </div>
                  <div
                    className="input_group mb-4 date_time_input day-time"
                    title={
                      !createFormData?.start_date_time &&
                      "Please select start date first"
                    }
                  >
                    <label htmlFor="">
                      End Date & Time{" "}
                      <span className="subLabel">(optional)</span>
                      {/* <sup>*</sup> */}
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDateTimePicker
                        label=" "
                        value={
                          createFormData?.end_date_time
                            ? dayjs(createFormData?.end_date_time)
                            : ""
                        }
                        className={` input_control end-date cald_icon ${
                          createFormData?.end_date_time
                            ? "avtive-input"
                            : "label-input label-input-date"
                        }
                          ${
                            Object.values(errors)?.length > 0 ||
                            !createFormData?.end_date_time ||
                            // || errors.end_date_time?.type === "required"
                            watch()?.end_date_time !== undefined
                              ? "require"
                              : ""
                          }`}
                        onChange={(val) =>
                          handleChangeStartEndDateAndTime(
                            new Date(val),
                            "end_date_time"
                          )
                        }
                        minDate={dayjs(createFormData?.start_date_time)}
                        disabled={!createFormData?.start_date_time}
                      />
                      {createFormData?.end_date_time ? (
                        <div className="clear-data remove-btn pointer">
                          <img
                            src="assets/images/remove.png"
                            onClick={() => clearData("end_date_time")}
                            alt="clear"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </LocalizationProvider>
                    {/* {Object.values(errors)?.length > 0 &&
                                            !createFormData?.end_date_time && (
                                                <p role="alert" className="alert-msg">
                                                    End Date & Time is required
                                                </p>
                                            )} */}
                    {createFormData?.end_date_time &&
                      moment(createFormData?.start_date_time) >=
                        moment(createFormData?.end_date_time) &&
                      DateValue && (
                        <p role="alert" className="alert-msg mt-3">
                          End Date & Time should not be less than or equal to
                          the Start Date & Time
                        </p>
                      )}
                  </div>
                  <div className="input_group mb-4 ">
                    <label htmlFor="">Days of Week</label>
                    <div className="typeh">
                      <Typeahead
                        className="text-input "
                        id="public-methods-example"
                        labelKey={(options) => options}
                        multiple
                        options={weekDaysList}
                        placeholder="Select days"
                        selected={selectedDays}
                        onChange={(e) => setSelectedDays(e)}
                        disabled={!createFormData?.start_date_time}
                      />
                    </div>
                  </div>

                  {/* -------------------  detail url -------------- */}
                  <div className="input_group mb-4 ">
                    <label htmlFor="">
                      {/* Event Details URL <sup>*</sup> */}
                      Event Details URL
                    </label>
                    <input
                      type="text"
                      className={`input_control ${
                        errors.purchase_url ? "require" : ""
                      }`}
                      placeholder="https://www.example.com"
                      {...register("purchase_url", {
                        // required: true,
                        pattern: {
                          value: urlPattern,
                          message: "Invalid URL",
                        },
                      })}
                    />
                    {/* {errors.purchase_url?.type === "required" && (
                      <p role="alert" className="alert-msg">
                        Event Details URL is required
                      </p>
                    )} */}
                    {/* {errors.purchase_url && (
                      <p className="alert-msg">{errors.purchase_url.message}</p>
                    )} */}
                  </div>
                  {/* -------------------  facebook url -------------- */}
                  <div className="input_group mb-4 ">
                    <label htmlFor="">
                      Event Facebook URL
                      <span className="subLabel">(optional)</span>
                    </label>
                    <input
                      type="text"
                      className="input_control"
                      placeholder="https://www.example.com"
                      {...register("social_facebook", {
                        pattern: {
                          value: urlPattern,
                          message: "Invalid URL  ",
                        },
                      })}
                    />
                    {errors.social_facebook && (
                      <p className="alert-msg">
                        {errors.social_facebook.message}
                      </p>
                    )}
                  </div>
                  {/* -------------------  instagram url -------------- */}
                  <div className="input_group mb-4 ">
                    <label htmlFor="">
                      Event Instagram URL
                      <span className="subLabel">(optional)</span>
                    </label>
                    <input
                      type="text"
                      className="input_control"
                      placeholder="https://www.example.com"
                      {...register("social_instagram", {
                        pattern: {
                          value: urlPattern,
                          message: "Invalid URL  ",
                        },
                      })}
                    />
                    {errors.social_instagram && (
                      <p className="alert-msg">
                        {errors.social_instagram.message}
                      </p>
                    )}
                  </div>
                  {/* -------------------  twitter url -------------- */}
                  <div className="input_group mb-4 ">
                    <label htmlFor="">
                      Event Twitter URL
                      <span className="subLabel">(optional)</span>
                    </label>
                    <input
                      type="text"
                      className="input_control"
                      placeholder="https://www.example.com"
                      {...register("social_twitter", {
                        pattern: {
                          value: urlPattern,
                          message: "Invalid URL  ",
                        },
                      })}
                    />
                    {errors.social_twitter && (
                      <p className="alert-msg">
                        {errors.social_twitter.message}
                      </p>
                    )}
                  </div>

                  {/* ------------------- Linked-in url -------------- */}
                  <div className="input_group mb-4 ">
                    <label htmlFor="">
                      Event Linkedin URL
                      <span className="subLabel">(optional)</span>
                    </label>
                    <input
                      type="text"
                      className="input_control"
                      placeholder="https://www.example.com"
                      {...register("social_linkedIn", {
                        pattern: {
                          value: urlPattern,
                          message: "Invalid URL  ",
                        },
                      })}
                    />
                    {errors.social_linkedIn && (
                      <p className="alert-msg">
                        {errors.social_linkedIn.message}
                      </p>
                    )}
                  </div>
                  <div className="input_group mb-4">
                    <div className="row">
                      <div className="col-12">
                        <label>
                          School Grade{" "}
                          <span className="subLabel">(optional)</span>
                        </label>
                      </div>
                      <div className="col-6">
                        <select
                          className="select_control select_icon-arrow"
                          {...register("school_grade_start")}
                        >
                          <option value="" className="option">
                            Min
                          </option>
                          {school_grade_list?.map((item, index) => {
                            return (
                              <option key={index + 1} value={item?.value}>
                                {item?.title}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-6">
                        <select
                          className="select_control select_icon-arrow"
                          {...register("school_grade_end")}
                        >
                          <option value="" className="option">
                            Max
                          </option>
                          {school_grade_list
                            ?.slice(
                              school_grade_list?.findIndex(
                                (a) => a?.title === watch()?.school_grade_start
                              ) + 1
                            )
                            ?.map((item, index) => {
                              return (
                                <option key={index + 1} value={item?.value}>
                                  {item?.title}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row px-0 mb-4">
                    <div className="col-12">
                      <label htmlFor="">
                        Set Age Range{" "}
                        <span className="subLabel">(optional)</span>
                      </label>
                    </div>
                    <div className="col-6">
                      <div className="input_group md_li_cen exmd_li_cen">
                        <input
                          type="text"
                          maxLength={100}
                          minLength={0}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          className={`input_control ${
                            errors.age_range_from ? "require" : ""
                          } `}
                          placeholder="0"
                          {...register("age_range_from", { min: 0, max: 100 })}
                          disabled={watch()?.all_age}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="input_group">
                        <input
                          type="text"
                          maxLength={100}
                          minLength={0}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          className={`input_control ${
                            errors.age_range_to ? "require" : ""
                          } `}
                          placeholder="100"
                          {...register("age_range_to", { min: 0, max: 100 })}
                          disabled={watch()?.all_age}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-center mt-1  custom-checkbox">
                        <input
                          type="checkbox"
                          className="me-2 checkbox"
                          id="ageset"
                          {...register("all_age")}
                        />
                        <label htmlFor="ageset" className="mb-0">
                          All ages welcome
                        </label>
                      </div>
                    </div>
                    {/* ========== age range errors  */}
                    {watch()?.age_range_from > 100 &&
                      watch()?.age_range_to > 100 && (
                        <p role="alert" className="alert-msg">
                          Age limit is 0 -100
                        </p>
                      )}
                    {watch()?.age_range_to !== undefined &&
                      watch()?.age_range_from !== undefined &&
                      !watch()?.all_age &&
                      parseInt(watch()?.age_range_from) >
                        parseInt(watch()?.age_range_to) && (
                        <p role="alert" className="alert-msg mt-2">
                          End age range is always greater than the start age
                          range
                        </p>
                      )}
                  </div>
                  <div className="input_group mb-4">
                    <label htmlFor="">
                      Gender <span className="subLabel">(optional)</span>
                    </label>
                    <select
                      className="select_control select_icon-arrow"
                      {...register("gender")}
                    >
                      <option value="" className="option">
                        Select Gender
                      </option>
                      {gender?.map((item, index) => {
                        return (
                          <option key={index + 1} value={item?.title}>
                            {item?.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="input_group mb-4">
                    <label htmlFor="">
                      Add Tags<span className="subLabel">(optional)</span>
                    </label>
                    <div
                      className={`${
                        selectedTag?.length >= 5 ? "disable-input" : ""
                      }`}
                    >
                      {/* <TagsInputCustom selectedTags={selectedTags}  /> */}

                      <TagsInput
                        classNames={{
                          tag: tagValue > Number(25) ? "alert-btn" : "",
                          input: tagValue > Number(25) ? "disable require" : "",
                        }}
                        value={selectedTag}
                        onChange={(e) => {
                          setSelectedTag(e);
                        }}
                        separators={[tagValue > Number(25) ? "" : "Enter"]}
                        onKeyUp={(e) => {
                          setTagValue(e.target.value.length);
                          if (e.target.value.length >= 5) {
                            return;
                          }
                        }}
                        name="fruits"
                        maxLength={10}
                        placeHolder="Enter Tags"
                      />
                      {tagValue > Number(25) && (
                        <p role="alert" className="alert-msg mt-2">
                          Tag text limit is 25 only
                        </p>
                      )}
                      {selectedTag?.length >= 5 && (
                        <p role="alert" className="alert-msg mt-2">
                          Max limit is 5
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 padd-left">
                  <div className="input_group mb-4">
                    <label> Cover Art</label>
                    <div className="file_upload_con">
                      <label htmlFor="uploadbanner" className="uploadbtn_file">
                        <input
                          ref={ref}
                          type="file"
                          accept="image/png, image/jpeg"
                          id="uploadbanner"
                          className="d-none"
                          onChange={(e) => {
                            if (e.target.files[0]?.size > 10e6) {
                              toast.error(
                                "Image size too large,upload upto 10MB.",
                                {
                                  autoClose: 3000,
                                }
                              );
                              return;
                            } else {
                              setCroperImage("0");
                              onSelectFile(e);
                              setImage(e.target.files[0]);
                            }
                          }}
                        />
                        <span className="d-block">
                          <img
                            // src={
                            //     eventBanner
                            //         ? URL.createObjectURL(eventBanner)
                            //         : "assets/images/Cloud_Upload.svg"
                            // }

                            src={
                              typeof eventBanner === "object"
                                ? URL.createObjectURL(eventBanner)
                                : typeof eventBanner === "string" &&
                                  eventBanner !== ""
                                ? eventBanner
                                : "assets/images/Cloud_Upload.svg"
                            }
                            alt="uploadimg"
                          />
                        </span>
                        {eventBanner?.name ? (
                          ""
                        ) : (
                          <span className="d-block">Upload Cover Art</span>
                        )}
                      </label>
                      {eventBanner?.name ? (
                        <div className="clear-data remove-btn pointer">
                          <img
                            src="assets/images/remove.png"
                            onClick={() => {
                              setEventBanner();
                              ref.current.value = "";
                            }}
                            alt="clear"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="input_group mb-4">
                    <label>Upload Event Pictures</label>
                    <div className="file_upload_con">
                      <label
                        htmlFor={eventImage?.length >= 4 ? "" : "eventImages"}
                        className={`uploadbtn_file ${
                          eventImage?.length >= 4 ? "disabled-data" : ""
                        }`}
                        title={
                          eventImage?.length >= 4
                            ? "Only 4 images are accepted."
                            : ""
                        }
                      >
                        <input
                          id="eventImages"
                          ref={eventImagesRef}
                          onChange={(e) => {
                            // handleImage(e);
                            setCroperImage("1");
                            onSelectFile(e);
                          }}
                          type="file"
                          name="files[]"
                          data-multiple-caption="{count} files selected"
                          multiple
                          accept=".jpg,.png,.jpeg "
                          className="d-none"
                        />
                        <span className="d-block">
                          <img
                            src={"assets/images/Cloud_Upload.svg"}
                            alt="uploadimg"
                          />
                        </span>
                        <span className="d-block">Upload Event Pictures</span>
                      </label>
                      <div className="upload_img_here">
                        {eventImage?.length > 0 &&
                          eventImage?.map((itm, index) => {
                            return (
                              <div
                                className="img_cont"
                                id={index + 1}
                                key={index + 1}
                              >
                                <div
                                  className="cross"
                                  onClick={() => {
                                    onRemoveAttachment(itm, index);
                                    eventImagesRef.current.value = "";
                                  }}
                                >
                                  <img
                                    src="assets/images/crossBtn.svg"
                                    alt="upload img"
                                    className="crossbtn"
                                  />
                                </div>
                                <img
                                  src={
                                    itm?.file?.name
                                      ? URL.createObjectURL(itm?.file)
                                      : `${process.env.REACT_APP_MEDIA_URL}/${itm}`
                                  }
                                  alt="upload img"
                                  className=" uploadedimg"
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>

                  <div className="input_group mb-4">
                    <label htmlFor="">
                      Contact Name <span className="subLabel">(optional)</span>
                    </label>
                    <input
                      type="text"
                      className="input_control"
                      placeholder="Contact Name"
                      {...register("contact_name")}
                    />
                  </div>
                  <div className="input_group mb-4">
                    <label htmlFor="">
                      Contact Email <span className="subLabel">(optional)</span>
                    </label>
                    <input
                      type="email"
                      className="input_control"
                      placeholder="Contact Email"
                      {...register("email")}
                    />
                  </div>
                  <div className="input_group mb-4">
                    <label htmlFor="">
                      Contact Phone <span className="subLabel">(optional)</span>
                    </label>
                    <div className="d-flex  contact-inputt">
                      {/* <select className="select_control select_icon-arrow"  {...register("country_code")} >
                                                <option value="+1">+1</option>
                                            </select> */}
                      <input
                        type="text"
                        maxLength={20}
                        minLength={0}
                        onKeyPress={(event) => {
                          if (!/[0-9+() ]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        className="input_control"
                        placeholder="Contact Phone"
                        {...register("phone")}
                      />
                    </div>
                  </div>
                  {/* {=======ticket price==========} */}
                  <div
                    className={`${watch()?.is_free ? "disabled-fields" : ""}`}
                  >
                    <div className={`setTicket mb-4 `}>
                      <div className="row justify-content-between">
                        <div className="col-7">
                          {" "}
                          <h4 className="head4 mb-0">Set Ticket Price</h4>
                        </div>
                        <div className="col-5 text-end">
                          <div className="d-flex align-items-center mt-1  custom-checkbox  justify-content-end">
                            <input
                              type="checkbox"
                              className="me-2 checkbox"
                              id="free-event"
                              {...register("is_free")}
                            />
                            <label htmlFor="free-event" className="mb-0">
                              Free
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className={`ticket_show`}>
                        <div className="d-flex align-items-center"></div>
                      </div>
                    </div>
                    {/* ------------------- registration fee -------------- */}
                    <div className="input_group mb-4 ">
                      <label htmlFor="">Registration Fee</label>
                      <div className="in_addicon me-2 threeinput_item">
                        <span>$</span>
                        <input
                          type="text"
                          maxLength={10}
                          minLength={0}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          value={watch("registration_fee")}
                          className="input_control"
                          placeholder="Amount"
                          {...register("registration_fee")}
                          disabled={watch()?.is_free}
                        />
                      </div>
                    </div>
                    <div className="input_group mb-4">
                      <label htmlFor="">Early Bird</label>
                      <div className="d-flex threeinput_fd">
                        <div className="in_addicon me-2 threeinput_item">
                          <span>$</span>
                          <input
                            type="text"
                            maxLength={10}
                            minLength={0}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            value={watch("ticket_early_bird_price")}
                            className="input_control"
                            placeholder="Amount"
                            {...register("ticket_early_bird_price")}
                            disabled={watch()?.is_free}
                          />
                        </div>
                        <div className="threeinput_item date_time_input w-100 me-2">
                          <>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <MobileDatePicker
                                label=" "
                                // disablePast
                                maxDate={dayjs(createFormData?.end_date_time)}
                                className={`${
                                  createFormData?.ticket_early_bird_start_date_time
                                    ? "avtive-input"
                                    : "label-input date-input"
                                }`}
                                disabled={watch()?.is_free}
                                value={
                                  createFormData?.ticket_early_bird_start_date_time
                                    ? dayjs(
                                        createFormData?.ticket_early_bird_start_date_time
                                      )
                                    : ""
                                }
                                onChange={(val) =>
                                  handleChangeDateAndTime(
                                    new Date(val),
                                    "ticket_early_bird_start_date_time"
                                  )
                                }
                              />
                              {createFormData?.ticket_early_bird_start_date_time ? (
                                <div className="clear-data remove-btn pointer">
                                  <img
                                    src="assets/images/remove.png"
                                    onClick={() =>
                                      clearData(
                                        "ticket_early_bird_start_date_time"
                                      )
                                    }
                                    alt="clear"
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </LocalizationProvider>
                          </>
                        </div>
                        <div
                          className="threeinput_item date_time_input w-100"
                          title={
                            !createFormData?.ticket_early_bird_start_date_time &&
                            "Please select start date first"
                          }
                        >
                          <>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <MobileDatePicker
                                label=" "
                                value={
                                  createFormData?.ticket_early_bird_end_date_time
                                    ? dayjs(
                                        createFormData?.ticket_early_bird_end_date_time
                                      )
                                    : ""
                                }
                                maxDate={dayjs(createFormData?.end_date_time)}
                                minDate={dayjs(
                                  createFormData?.ticket_early_bird_start_date_time
                                )}
                                className={` ${
                                  createFormData?.ticket_early_bird_end_date_time
                                    ? "avtive-input"
                                    : "label-input date-input"
                                }`}
                                onChange={(val) =>
                                  handleChangeDateAndTime(
                                    new Date(val),
                                    "ticket_early_bird_end_date_time"
                                  )
                                }
                                disabled={
                                  !createFormData?.ticket_early_bird_start_date_time ||
                                  watch()?.is_free
                                }
                                // shouldDisableDate={(e) => shouldDisableDate(e, createFormData?.ticket_early_bird_start_date_time)}
                              />
                              {createFormData?.ticket_early_bird_end_date_time ? (
                                <div className="clear-data remove-btn pointer">
                                  <img
                                    src="assets/images/remove.png"
                                    onClick={() =>
                                      clearData(
                                        "ticket_early_bird_end_date_time"
                                      )
                                    }
                                    alt="clear"
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </LocalizationProvider>
                          </>
                        </div>
                      </div>
                    </div>
                    <div className="input_group mb-4">
                      <label htmlFor="">Standard </label>
                      <div className="d-flex threeinput_fd">
                        <div className="threeinput_item in_addicon me-2 ">
                          <span>$</span>
                          <input
                            type="text"
                            maxLength={10}
                            minLength={0}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            className="input_control"
                            placeholder="Amount"
                            {...register("ticket_standard_price")}
                            disabled={watch()?.is_free}
                          />
                        </div>
                        <div className="threeinput_item date_time_input w-100 me-2">
                          <>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <MobileDatePicker
                                label="  "
                                value={
                                  createFormData?.ticket_standard_start_date_time
                                    ? dayjs(
                                        createFormData?.ticket_standard_start_date_time
                                      )
                                    : ""
                                }
                                // disablePast
                                maxDate={dayjs(createFormData?.end_date_time)}
                                className={` ${
                                  createFormData?.ticket_standard_start_date_time
                                    ? "avtive-input"
                                    : "label-input date-input"
                                }`}
                                disabled={watch()?.is_free}
                                onChange={(val) =>
                                  handleChangeDateAndTime(
                                    new Date(val),
                                    "ticket_standard_start_date_time"
                                  )
                                }
                              />
                              {createFormData?.ticket_standard_start_date_time ? (
                                <div className="clear-data remove-btn pointer">
                                  <img
                                    src="assets/images/remove.png"
                                    onClick={() =>
                                      clearData(
                                        "ticket_standard_start_date_time"
                                      )
                                    }
                                    alt="clear"
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </LocalizationProvider>
                          </>
                        </div>
                        <div
                          className="threeinput_item date_time_input w-100"
                          title={
                            !createFormData?.ticket_standard_start_date_time &&
                            "Please select start date first"
                          }
                        >
                          <>
                            {" "}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <MobileDatePicker
                                label="  "
                                maxDate={dayjs(createFormData?.end_date_time)}
                                minDate={dayjs(
                                  createFormData?.ticket_standard_start_date_time
                                )}
                                className={` ${
                                  createFormData?.ticket_standard_end_date_time
                                    ? "avtive-input"
                                    : "label-input date-input"
                                }`}
                                value={
                                  createFormData?.ticket_standard_end_date_time
                                    ? dayjs(
                                        createFormData?.ticket_standard_end_date_time
                                      )
                                    : ""
                                }
                                disabled={
                                  !createFormData?.ticket_standard_start_date_time ||
                                  watch()?.is_free
                                }
                                // shouldDisableDate={(e) => shouldDisableDate(e, createFormData?.ticket_standard_start_date_time)}
                                onChange={(val) =>
                                  handleChangeDateAndTime(
                                    new Date(val),
                                    "ticket_standard_end_date_time"
                                  )
                                }
                              />
                              {createFormData?.ticket_standard_end_date_time ? (
                                <div className="clear-data remove-btn pointer">
                                  <img
                                    src="assets/images/remove.png"
                                    onClick={() =>
                                      clearData("ticket_standard_end_date_time")
                                    }
                                    alt="clear"
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </LocalizationProvider>
                          </>
                        </div>
                      </div>
                    </div>
                    <div className="input_group mb-4">
                      <label htmlFor="">Very Late</label>
                      <div className="d-flex  threeinput_fd">
                        <div className="threeinput_item in_addicon me-2">
                          <span>$</span>
                          <input
                            type="text"
                            maxLength={10}
                            minLength={0}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            className="input_control"
                            placeholder="Amount"
                            {...register("ticket_late_price")}
                            disabled={watch()?.is_free}
                          />
                        </div>
                        <div className="threeinput_item date_time_input w-100 me-2">
                          <>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <MobileDatePicker
                                label=" "
                                maxDate={dayjs(createFormData?.end_date_time)}
                                // disablePast
                                className={` ${
                                  createFormData?.ticket_late_start_date_time
                                    ? "avtive-input"
                                    : "label-input date-input"
                                }`}
                                value={
                                  createFormData?.ticket_late_start_date_time
                                    ? dayjs(
                                        createFormData?.ticket_late_start_date_time
                                      )
                                    : ""
                                }
                                disabled={watch()?.is_free}
                                onChange={(val) =>
                                  handleChangeDateAndTime(
                                    new Date(val),
                                    "ticket_late_start_date_time"
                                  )
                                }
                              />
                              {createFormData?.ticket_late_start_date_time ? (
                                <div className="clear-data remove-btn pointer">
                                  <img
                                    src="assets/images/remove.png"
                                    onClick={() =>
                                      clearData("ticket_late_start_date_time")
                                    }
                                    alt="clear"
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </LocalizationProvider>
                          </>
                        </div>
                        <div
                          className="threeinput_item date_time_input w-100"
                          title={
                            !createFormData?.ticket_late_start_date_time &&
                            "Please select start date first"
                          }
                        >
                          <>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <MobileDatePicker
                                label="  "
                                maxDate={dayjs(createFormData?.end_date_time)}
                                minDate={dayjs(
                                  createFormData?.ticket_late_start_date_time
                                )}
                                className={` ${
                                  createFormData?.ticket_late_end_date_time
                                    ? "avtive-input"
                                    : "label-input date-input"
                                }`}
                                value={
                                  createFormData?.ticket_late_end_date_time
                                    ? dayjs(
                                        createFormData?.ticket_late_end_date_time
                                      )
                                    : ""
                                }
                                disabled={
                                  !createFormData?.ticket_late_start_date_time ||
                                  watch()?.is_free
                                }
                                // shouldDisableDate={(e) => shouldDisableDate(e, createFormData?.ticket_late_start_date_time)}
                                onChange={(val) =>
                                  handleChangeDateAndTime(
                                    new Date(val),
                                    "ticket_late_end_date_time"
                                  )
                                }
                              />
                              {createFormData?.ticket_late_end_date_time ? (
                                <div className="clear-data remove-btn pointer">
                                  <img
                                    src="assets/images/remove.png"
                                    onClick={() =>
                                      clearData("ticket_late_end_date_time")
                                    }
                                    alt="clear"
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </LocalizationProvider>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="submit_data_content">
                    <button className="cusbtn w-100">
                      {id ? "Update" : "Publish"} Event
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* -----------------* croper */}
      <Modal
        backdrop="static"
        show={showImageForCropModal}
        // onHide={() => { setShowImageForCropModal(false); ref.current.value=""}}
        centered
        className="croper-modal"
      >
        <Modal.Header>
          <Modal.Title>Crop Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="position-relative w-100  croper-body">
            {cropItem && (
              <>
                <Cropper
                  ref={cropperRef}
                  style={{ height: "100%", width: "100%" }}
                  zoomTo={0}
                  // initialAspectRatio={croperImage === "0"?(771/364):(264/190)}
                  // aspectRatio={croperImage === "0" ? 771 / 364 : 264 / 194}
                  preview=".img-preview"
                  src={cropItem}
                  viewMode={1}
                  // minCropBoxWidth={croperImage === "0" ? 771 : 264}
                  // minCropBoxHeight={croperImage === "0" ? 364 : 194}
                  background={false}
                  responsive={true}
                  disableguestresize={false}
                  autoCropArea={0}
                  checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                  guides={true}
                  zoomable={false}
                />
              </>
            )}
          </div>
          <div className="delete-btn d-flex croper-footer">
            <button
              className="cusbtn update-btn"
              onClick={() => {
                setShowImageForCropModal(false);
                getCropData();
                ref.current.value = "";
                eventImagesRef.current.value = "";
              }}
            >
              Upload
            </button>
            <button
              className="cusbtn cancel-btn"
              onClick={() => {
                setShowImageForCropModal(false);
                ref.current.value = "";
                eventImagesRef.current.value = "";
              }}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showCardModal}
        centered
        onHide={() => setShowCardModal(false)}
        className="delete-modal"
        size="md"
      >
        {showCardModal && (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm />
          </Elements>
        )}
      </Modal>
    </>
  );
}

export default CreateEvent;
