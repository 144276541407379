/**
 * @method [capitalizeString] to capital first letter of the string
 * @param {String} str
 * @returns String
 */
export const capitalizeString = (str) => {
  const arr = str?.split(" ");
  for (let i = 0; i < arr?.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr?.join(" ");
};

export function loadScript(src, value) {
  return new Promise((resolve) => {
    const script = document?.createElement("script");
    if (src) {
      script.src = src;
    }

    if (value) {
      script.innerHTML = value;
    }
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

// Show dots after text lenght....

export const text_truncate = (str, length, ending) => {
  if (length == null) {
    length = 10;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

// trim text
export const trimText = (str) => {
  return str && str?.length > 50 ? str.substring(0, 70) + "..." : str || "";
};

// console.log(text_truncate("We are doing JS string exercises."));
// console.log(text_truncate("We are doing JS string exercises.", 19));
// console.log(text_truncate("We are doing JS string exercises.", 15, "!!"));

export function getHeaderData() {
  return {
    // Authorization: `Bearer ${token}`,
    Accept: "application/json",
    "Content-Type": "application/json",
  };
}

export async function postformData(data) {
  return {
    headers: await getHeaderData(),
    method: "POST",
    body: JSON.stringify(data),
  };
}

export async function patch(data) {
  return {
    headers: await getHeaderData(),
    method: "PATCH",
    body: JSON.stringify(data),
  };
}
export async function put(data) {
  return {
    headers: await getHeaderData(),
    method: "PUT",
    body: JSON.stringify(data),
  };
}
export async function fetchDelete(data) {
  return {
    headers: await getHeaderData(),
    method: "DELETE",
    body: JSON.stringify(data),
  };
}
