import React, { useEffect } from "react";
import { loadScript } from "../helpers/helper";
var divStyle = { display: "block" };
const ShowSquareAd = () => {
  useEffect(() => {
    loadScript(
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3571706419332962"
    );
    loadScript("", " (adsbygoogle = window.adsbygoogle || []).push({});");
  }, []);

  return (
    <div>
      {/* <!-- Horizontal ad --> */}

      <ins
        class="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-3571706419332962"
        data-ad-slot="9492628780"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
};

export default ShowSquareAd;
