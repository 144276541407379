import React from "react";
import Header from "../header/Header";
import Banner from "../banner/Banner";
import ShowNowAdds from "../shop-now-add/ShopNowAdds";
import NewsLetter from "../news-letter/NewsLetter";
import GetItNow from "../get-it-now/GetItNow";
import Footer from "../footer/Footer";
import { Outlet } from "react-router-dom";
import Auth from "../auth/Auth";

console.log(Auth.token(), "Auth.isUserAuthenticated()");
const DashboardLayout = () => {
  return (
    <div>
      <Header />
      <Banner />
      <section className="upcoming-event">
        <div className="container event-box">
          <div className="left">
            {/* {!Auth.token() ? <Navigate to="/login" /> : <Outlet />} */}
            <Outlet />
          </div>
          <div className="right">
            <ShowNowAdds />
            <NewsLetter />
            <GetItNow />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default DashboardLayout;
