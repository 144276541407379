import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Auth from "../auth/Auth";

const OnboardLayout = () => {
  return (
    <>
      <div className="onboarding">
        <div className="wrapper-container">
          <div className="col-left">
            <div className="login-container">
              <div className="logo-area text-center mb-md-5 mb-4">
                <img
                  src="assets/images/dshLogo.png"
                  alt="logo"
                  className="onboardLogo"
                />
              </div>
              {Auth.token() ? <Navigate to="/" /> : <Outlet />}
              {/* <Outlet /> */}
            </div>
          </div>
          <div className="col-right">
            <img
              src="assets/images/onboading_bg.png"
              alt="img"
              className="w-100"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OnboardLayout;
