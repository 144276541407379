import React from "react";
import Footer from "../footer/Footer";
import { Outlet } from "react-router-dom";

const DetailLayout = () => {
  return (
    <div>
      <div className="left">
        {/* {Auth.isUserAuthenticated() ? <Navigate to="/" /> : <Outlet />} */}
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default DetailLayout;
