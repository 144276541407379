import React, { useCallback, useContext, useEffect, useState } from "react";
import { Nav, NavDropdown, NavLink } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../App";
import Auth from "../auth/Auth";
import ShowHorizontalAd from "../shop-now-add/ShowHorizontalAd";
import ShowSquareAd from "../shop-now-add/ShowSquareAd";

function Banner() {
  const handleClick = async (event, pages) => {
    event.currentTarget.classList.toggle("nightlife-active");
    localStorage.setItem("nightLife", event.currentTarget.classList?.[1]);
    setShowLoader(true);
    const requestOption = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Auth.token()}`,
      },
      withCredentials: false,
    };
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}event-data-home?page=${
        pages ? pages : 1
      }&sortBy=start_date_time${`&nightlife=${
        event.currentTarget.classList?.[1] === "nightlife-active" ? 1 : 0
      }`}`,
      requestOption
    );

    if (res.status >= 200 && res.status <= 399) {
      const data = await res.json();
      setIsData(data);
      setShowLoader(false);
      setTimeout(() => window.scrollTo(0, 0), 0);
    }
    if (res.status === 401) {
      localStorage.removeItem("__WTD_WEB__");
      navigate("/");
    }

    if (res.status >= 400 && res.status <= 500) {
      let error = await res.json();
      console.log(error);
      setShowLoader(false);
    }
  };

  // const [isDropData, setIsDropData] = useState([]);
  const navigate = useNavigate();

  const {
    selecteState,
    setSelecteState,
    setShowLoader,
    setIsData,
    pages,
    setPage,
    dropLoaction,
    searchHandle,
    isDropData,
    setIsDropData,
    selectEvent,
    feature,
    selectEventGender,
    minAge,
    maxAge,
    setSelectEvent,
    selected,
    selectedGradeTo,
    date,
    endDate,
    selectedDays,
    startPriceValue,
    endPriceValue,
    isData,
  } = useContext(GlobalContext);

  console.log(date, "date");

  /**
   * @method [getDropDownData] to show the listing of the records and according to the keyword also
   
   */

  const getDropDownData = useCallback(async () => {
    const requestOption = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Auth.token()}`,
      },
    };
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}event-category?sortBy=name`,
      requestOption
    );

    if (res.status >= 200 && res.status <= 399) {
      const data = await res.json();
      setIsDropData(data);
    }
    if (res.status === 401) {
      console.log("err");
    }

    if (res.status >= 400 && res.status <= 500) {
      let error = await res.json();
      console.log(error);
    }
  }, []);

  /**
   * @method [getEventData] to show the listing of the records and according to the keyword also
   
   */

  console.log(selectedDays, "selectedDays");
  const getEventData = useCallback(
    async (
      pages,
      selectEvent,
      feature,
      selectEventGender,
      minAge,
      maxAge,
      selected,
      selectedGradeTo,
      date,
      endDate,
      selectedDays,
      startPriceValue,
      endPriceValue
    ) => {
      setShowLoader(true);
      const requestOption = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Auth.token()}`,
        },
        withCredentials: false,
      };
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}event-data-home?page=${
          pages ? pages : 1
        }&sortBy=start_date_time${
          selectEvent?.length !== 0 ? `&category=${selectEvent?.join()}` : ""
        }${feature === true ? `&featured=${1}` : ""}${
          selectEventGender?.length !== 0
            ? `&gender=${selectEventGender?.join()}`
            : ""
        }${minAge ? `&age_range_from=${minAge}` : ""}${
          maxAge ? `&age_range_to=${maxAge}` : ""
        }${selected ? `&school_grade_start=${selected}` : ""}${
          selectedGradeTo ? `&school_grade_end=${selectedGradeTo}` : ""
        }${date ? `&start_date_time=${date}` : ""}${
          endDate ? `&end_date_time=${endDate}` : ""
        }${
          selectedDays && selectedDays?.length !== 0
            ? `&days_of_week=${selectedDays.map((u) => u.name).join(",")}`
            : ""
        }${startPriceValue ? `&price_start=${startPriceValue}` : ""}${
          endPriceValue ? `&price_end=${endPriceValue}` : ""
        }${`&nightlife=${
          localStorage.getItem("nightLife") === "nightlife-active" ? 1 : 0
        }`}`,
        requestOption
      );

      if (res.status >= 200 && res.status <= 399) {
        const data = await res.json();
        setIsData(data);
        setShowLoader(false);
        setTimeout(() => window.scrollTo(0, 0), 0);
      }
      if (res.status === 401) {
        localStorage.removeItem("__WTD_WEB__");
        navigate("/");
      }

      if (res.status >= 400 && res.status <= 500) {
        let error = await res.json();
        console.log(error);
        setShowLoader(false);
      }
    },
    []
  );

  function handleChange(e) {
    setSelecteState(e.target.value);
    navigate(`category/${e.target.value}`);
  }

  useEffect(() => {
    getDropDownData();
  }, []);

  useEffect(() => {
    getEventData(
      pages,
      selectEvent,
      feature,
      selectEventGender,
      minAge,
      maxAge,
      selected,
      selectedGradeTo,
      date,
      endDate,
      selectedDays,
      startPriceValue,
      endPriceValue
    );
  }, [
    pages,
    selectEvent,
    feature,
    selectEventGender,
    minAge,
    maxAge,
    selected,
    selectedGradeTo,
    date,
    endDate,
    selectedDays,
    startPriceValue,
    endPriceValue,
  ]);

  // Reset all filter
  const handleResetFilter = () => {
    navigate("/");
    window.location.reload(true);
  };

  useEffect(() => {
    if (localStorage?.getItem("selectEvent")?.length)
      setSelectEvent(JSON.parse(localStorage?.getItem("selectEvent")));
  }, [localStorage?.getItem("selectEvent")]);
  console.log(isData, "asdkalsd");

  return (
    <>
      <section className="banner">
        <div className="container d-flex">
          <div className="logo">
            <img src={`${process.env.REACT_APP_LOCAL_IMG}logo2.svg`} alt="" />
          </div>

          <div className="showWeb">
            <ShowHorizontalAd />
          </div>

          <div
            className="showMob"
            style={{ width: "100%", maxHeight: "300px" }}
          >
            {<ShowSquareAd />}
          </div>
          {/* <div className="sport-type">
            <img src={`${process.env.REACT_APP_LOCAL_IMG}aise.svg`} alt="" />
          </div> */}
        </div>
      </section>
      <section className="event-category">
        <div className="container d-flex">
          <Nav className="category">
            <NavDropdown
              title={
                dropLoaction?.state?.from?.name
                  ? dropLoaction?.state?.from?.name
                  : "All Events"
              }
              value={selecteState}
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item
                to="/"
                state={{ from: "All Events" }}
                onClick={handleResetFilter}
                as={Link}
              >
                All Events
              </NavDropdown.Item>
              {isDropData?.data?.length > 0 > 0
                ? isDropData?.data?.map((events, index) => (
                    <NavDropdown.Item
                      to={`/category/${events?.slug}`}
                      state={{ from: events }}
                      key={index}
                      as={Link}
                      // onClick={(e) => setSelecteState(e.target.value)}
                    >
                      {events?.name}
                    </NavDropdown.Item>
                  ))
                : "No data Found"}
            </NavDropdown>
          </Nav>

          <div
            className={
              localStorage.getItem("nightLife") === "nightlife-active"
                ? "night-life nightlife-active"
                : "night-life"
            }
            onClick={handleClick}
          >
            Parties & Nightlife
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner;
