import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axois, { headers } from "../../http/axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../common/Spinner";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    // data.role = "admin";
    setLoading(true);
    axois
      .post("auth/password/forgot", data, {
        headers: headers,
      })
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
          navigate("/verification_code", { state: { email: data.email } });
        }, 0);
      })
      .catch((err) => {
        toast.error("Please enter the valid email");
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <div>
      <div className="form_onboard">
        <h3 className="heading">Forgot Password</h3>
        <p className="textp">Please enter your email</p>
        <div className="form-area">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="inputGroup mb20">
              <label>Email</label>
              <div className="inputAdd_icon">
                <input
                  type="email"
                  className="inputControl"
                  placeholder="Enter  Email"
                  autoComplete="Enter  Email"
                  {...register("email", {
                    required: true,
                    pattern: /\S+@\S+\.\S+/,
                  })}
                />
                <span class="inputicon">
                  <img src="assets/images/atrate_icon.svg" alt="icon" />
                </span>
              </div>
              {errors.email?.type === "required" && (
                <p role="alert" className="text-danger">
                  Email is required
                </p>
              )}
              {errors.email?.type === "pattern" && (
                <p role="alert" className="text-danger">
                  Incorrect email format
                </p>
              )}
            </div>

            <div className="btn_sub mt-5">
              <button className="cusbtn w-100" type="submit">
                Submit
              </button>
            </div>
            <p className="textp btxt text-center mt-4">
              Back to{" "}
              <Link to="/login" className="linkOn">
                Login
              </Link>
            </p>
          </form>
        </div>
      </div>
      {loading && <Spinner />}
    </div>
  );
};

export default ForgotPassword;
