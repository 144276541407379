import React, { useCallback, useContext, useEffect, useState } from "react";
import { Accordion, Dropdown } from "react-bootstrap";
import { days_of_week, gender, school_grade_list } from "./TagArray";
import { AppContext } from "./AppContext";
import { GlobalContext } from "../../App";
import { Typeahead } from "react-bootstrap-typeahead";

const Filter = () => {
  const {
    isDropData,
    selectEvent,
    setSelectEvent,
    setFeature,
    feature,
    setSelectEventGender,
    selectEventGender,
    setMinAge,
    minAge,
    setMaxAge,
    maxAge,
    selected,
    setSelected,
    selectedGradeTo,
    setSelectedGradeTo,
    date,
    setDate,
    endDate,
    setEndDate,
    daysMulti,
    setDaysMulti,
    selectedDays,
    setSelectedDays,
    startPriceValue,
    setStartPriceValue,
    endPriceValue,
    setEndPriceValue,
  } = useContext(GlobalContext);

  // gradeFrom function
  const handleChangeGradeFrom = (event) => {
    setSelected(event.target.value);
    if (!event.target.value) {
      return localStorage.removeItem("selectEventGradeFrom");
    }
  };

  // setItme in localstorage for category with no load

  useEffect(() => {
    if (selected)
      localStorage.setItem("selectEventGradeFrom", JSON.stringify(selected));
  }, [selected]);

  // getItme in localstorage for category with no load

  // useEffect(() => {
  //   if (localStorage?.getItem("selectEventGradeFrom")?.length)
  //     setSelected(JSON.parse(localStorage?.getItem("selectEventGradeFrom")));
  // }, [localStorage?.getItem("selectEventGradeFrom")]);

  // gradeTo function
  const handleChangeGradeTo = (event) => {
    setSelectedGradeTo(event.target.value);
    if (!event.target.value) {
      return localStorage.removeItem("selectEventGradeTo");
    }
  };

  // setItme in localstorage for category with no load

  useEffect(() => {
    if (selectedGradeTo)
      localStorage.setItem(
        "selectEventGradeTo",
        JSON.stringify(selectedGradeTo)
      );
  }, [selectedGradeTo]);

  // getItme in localstorage for category with no load

  // useEffect(() => {
  //   if (localStorage?.getItem("selectEventGradeTo")?.length)
  //     setSelectedGradeTo(
  //       JSON.parse(localStorage?.getItem("selectEventGradeTo"))
  //     );
  // }, [localStorage?.getItem("selectEventGradeTo")]);

  // category checkbox
  const handleChangeCheckBox = useCallback(
    async (e, data) => {
      const { checked } = e.target;
      setSelectEvent((prevState) => {
        return checked
          ? [...prevState, data]
          : localStorage.removeItem("selectEvent") ||
              prevState.filter((item) => item !== data);
      });
    },
    [selectEvent]
  );

  // setItme in localstorage for category with no load

  useEffect(() => {
    if (selectEvent?.length > 0)
      localStorage.setItem("selectEvent", JSON.stringify(selectEvent));
  }, [selectEvent]);

  // getItme in localstorage for category with no load

  useEffect(() => {
    if (localStorage?.getItem("selectEvent")?.length)
      setSelectEvent(JSON.parse(localStorage?.getItem("selectEvent")));
  }, [localStorage?.getItem("selectEvent")]);

  // category checkbox
  const handleChangeCheckBoxGender = (e, data) => {
    const { checked } = e.target;
    setSelectEventGender((prevState) => {
      return checked
        ? [...prevState, data]
        : localStorage.removeItem("genderCheckBox") ||
            prevState.filter((item) => item !== data);
    });
  };

  // setItme in localstorage for gender with no load

  useEffect(() => {
    if (selectEventGender?.length > 0)
      localStorage.setItem("genderCheckBox", JSON.stringify(selectEventGender));
  }, [selectEventGender]);

  // getItme in localstorage for gender with no load

  // useEffect(() => {
  //   if (localStorage?.getItem("genderCheckBox")?.length)
  //     setSelectEventGender(JSON.parse(localStorage?.getItem("genderCheckBox")));
  // }, [localStorage?.getItem("genderCheckBox")]);

  // feature toggel
  const handleFeturedCheck = (e) => {
    const { checked } = e.target;
    setFeature(checked);
    if (checked === false) {
      return localStorage.removeItem("FeatureEvent");
    }
  };

  // setItme in localstorage for feature toggle with no load

  useEffect(() => {
    if (feature) localStorage.setItem("FeatureEvent", JSON.stringify(feature));
  }, [feature]);

  // getItme in localstorage for feature toggle with no load

  useEffect(() => {
    if (localStorage?.getItem("FeatureEvent"))
      setFeature(JSON.parse(localStorage?.getItem("FeatureEvent")));
  }, [localStorage?.getItem("FeatureEvent")]);

  // start date function
  const onDateChange = (event) => {
    setDate(event.target.value);
    if (!event) {
      return localStorage.removeItem("start_date");
    }
  };
  // setItme in localstorage for start date with no load

  useEffect(() => {
    if (date) localStorage.setItem("start_date", JSON.stringify(date));
  }, [date]);

  console.log(date, ">>>>>>>>>");

  // getItme in localstorage for startdate with no load
  // useEffect(() => {
  //   if (localStorage?.getItem("start_date"))
  //     setDate(JSON.parse(localStorage?.getItem("start_date")));
  // }, [localStorage?.getItem("start_date")]);

  // end date function
  const onEndDateChange = (event) => {
    setEndDate(event.target.value);
    if (!event) {
      return localStorage.removeItem("end_date");
    }
  };
  // setItme in localstorage for end date with no load

  useEffect(() => {
    if (endDate) localStorage.setItem("end_date", JSON.stringify(endDate));
  }, [endDate]);

  // getItme in localstorage for endDate with no load
  // useEffect(() => {
  //   if (localStorage?.getItem("end_date"))
  //     setEndDate(JSON.parse(localStorage?.getItem("end_date")));
  // }, [localStorage?.getItem("end_date")]);

  // MinAge function

  const handleMinAge = (data) => {
    setMinAge(data);
    if (!data) {
      return localStorage.removeItem("MinAge");
    }
  };

  // setItme in localstorage for MinAge with no load

    useEffect(() => {
      if (minAge) localStorage.setItem("MinAge", JSON.stringify(minAge));
    }, [minAge]);

  // getItme in localstorage for MinAge with no load

  useEffect(() => {
    if (localStorage?.getItem("MinAge"))
      setMinAge(JSON.parse(localStorage?.getItem("MinAge")));
  }, [localStorage?.getItem("MinAge")]);

  // MaxAge function

  const handleMaxAge = (data) => {
    setMinAge(data);
    if (!data) {
      return localStorage.removeItem("MaxAge");
    }
  };
  // setItme in localstorage for MaxAge with no load

  useEffect(() => {
    if (maxAge) localStorage.setItem("MaxAge", JSON.stringify(maxAge));
  }, [maxAge]);

  // getItme in localstorage for MaxAge with no load

  // useEffect(() => {
  //   if (localStorage?.getItem("MaxAge"))
  //     setMaxAge(JSON.parse(localStorage?.getItem("MaxAge")));
  // }, [localStorage?.getItem("MaxAge")]);

  // Reset all filter
  const handleResetFilter = () => {
    localStorage.removeItem("MaxAge");
    localStorage.removeItem("MinAge");
    localStorage.removeItem("FeatureEvent");
    localStorage.removeItem("genderCheckBox");
    localStorage.removeItem("selectEvent");
    localStorage.removeItem("selectEventGradeTo");
    localStorage.removeItem("selectEventGradeFrom");
    localStorage.removeItem("MaxAge");
    localStorage.removeItem("MaxAge");
    localStorage.removeItem("MaxAge");
    localStorage.removeItem("MaxAge");
    localStorage.removeItem("start_date");
    localStorage.removeItem("end_date");
    localStorage.removeItem("startPriceValue");
    localStorage.removeItem("days_of_week");
    localStorage.removeItem("endPriceValue");

    window.location.reload(true);
  };

  const minDate = () => {
    const today = new Date().toISOString().split("T")[0];
    return today;
  };

  // days_of_week function
  const onSelectDays = (event) => {
    setSelectedDays(event);
    if (event?.length < 1) {
      localStorage.removeItem("days_of_week");
      // window.location.reload(true);
    }
  };
  // setItme in localstorage for days_of_week with no load

  useEffect(() => {
    if (selectedDays)
      localStorage.setItem("days_of_week", JSON.stringify(selectedDays));
  }, [selectedDays]);

  // getItme in localstorage for days_of_week with no load
  // useEffect(() => {
  //   if (localStorage?.getItem("days_of_week"))
  //     setSelectedDays(JSON.parse(localStorage?.getItem("days_of_week")));
  // }, [localStorage?.getItem("days_of_week")]);

  // StartPriceValue function

  const handleStartPrice = (data) => {
    data.includes("$") && (data = data.slice(1));
    setStartPriceValue(data);
    if (!data) {
      return localStorage.removeItem("startPriceValue");
    }
  };

  // setItme in localstorage for StartPriceValue with no load

  useEffect(() => {
    if (startPriceValue)
      localStorage.setItem("startPriceValue", JSON.stringify(startPriceValue));
  }, [startPriceValue]);

  // getItme in localstorage for StartPriceValue with no load

  // useEffect(() => {
  //   if (localStorage?.getItem("startPriceValue"))
  //     setStartPriceValue(JSON.parse(localStorage?.getItem("startPriceValue")));
  // }, [localStorage?.getItem("startPriceValue")]);

  // endPriceValue function

  const handleEndPrice = (data) => {
    data.includes("$") && (data = data.slice(1));

    setEndPriceValue(data);
    if (!data) {
      return localStorage.removeItem("endPriceValue");
    }
  };
  // setItme in localstorage for endPriceValue with no load

  useEffect(() => {
    if (endPriceValue)
      localStorage.setItem("endPriceValue", JSON.stringify(endPriceValue));
  }, [endPriceValue]);

  // getItme in localstorage for endPriceValue with no load

  // useEffect(() => {
  //   if (localStorage?.getItem("endPriceValue"))
  //     setEndPriceValue(JSON.parse(localStorage?.getItem("endPriceValue")));
  // }, [localStorage?.getItem("endPriceValue")]);

  const currentcyFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });


  const [show, setShow] = React.useState(true);
  return (
    <div className={show ? "showdropdown select-box" : "select-box"}>
      <Dropdown className={!show ? "show" : ""}>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          <span className="filter_btn">
            {selectEvent?.length ||
            feature ||
            selectEventGender?.length > 0 ||
            minAge ||
            maxAge ||
            selected?.length ||
            selectedGradeTo?.length ||
            selectedDays?.length > 0 ||
            date ||
            endDate ||
            startPriceValue ||
            endPriceValue ? (
              <img
                src={`${process.env.REACT_APP_LOCAL_IMG}filterclr.svg`}
                alt=""
              />
            ) : (
              <img
                src={`${process.env.REACT_APP_LOCAL_IMG}greyfilter.svg`}
                alt=""
              />
            )}
          </span>
          <span className="d-md-inline d-none">-- Select Filter--</span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Accordion
          // defaultActiveKey="0"
          >
            {/* <Accordion.Item eventKey="0">
              <Accordion.Header>Category</Accordion.Header>
              <Accordion.Body>
                {isDropData &&
                  isDropData?.data?.map((item, index) => {
                    return (
                      <label class="check_box" key={index}>
                        <input
                          type="checkbox"
                          class="checkbox chassis_make"
                          checked={selectEvent?.includes(item?.id)}
                          onChange={(e) => handleChangeCheckBox(e, item?.id)}
                        />
                        <span>{item?.slug}</span>
                        <span class="checkbox_check"></span>
                      </label>
                    );
                  })}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Featured</Accordion.Header>
              <Accordion.Body>
                <div class="slide-pin">
                  <input type="checkbox" onChange={handleFeturedCheck} />
                </div>
              </Accordion.Body>
            </Accordion.Item> */}
            <Accordion.Item eventKey="2">
              <Accordion.Header>Grade</Accordion.Header>
              <Accordion.Body>
                <div className="grade priceG">
                  <div className="min">
                    <select value={selected} onChange={handleChangeGradeFrom}>
                      <option value="" className="option">
                        Min
                      </option>
                      {school_grade_list?.map((item, index) => {
                        return (
                          <option key={index + 1} value={item?.value}>
                            {item?.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <span>-</span>
                  <div className="max">
                    <select
                      value={selectedGradeTo}
                      onChange={handleChangeGradeTo}
                    >
                      <option value="" className="option">
                        Max
                      </option>
                      {school_grade_list
                        ?.slice(
                          school_grade_list?.findIndex(
                            (a) => a?.title === selected
                          ) + 1
                        )
                        ?.map((item, index) => {
                          return (
                            <option key={index + 1} value={item?.value}>
                              {item?.title}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Gender</Accordion.Header>
              <Accordion.Body>
                {gender?.map((genderItm, index) => {
                  return (
                    <label class="check_box" key={genderItm.title}>
                      <input
                        type="checkbox"
                        className="checkbox chassis_make"
                       
                        checked={selectEventGender.some(
                          (item) => item === genderItm.title
                        )}
                        onChange={(e) =>
                          handleChangeCheckBoxGender(e, genderItm?.title)
                        }
                      />
                      <span>{genderItm?.title}</span>
                      <span class="checkbox_check"></span>
                    </label>
                  );
                })}
              </Accordion.Body>
            </Accordion.Item>
            {/* <Accordion.Item eventKey="4">
              <Accordion.Header>Age</Accordion.Header>
              <Accordion.Body>
                <div className="grade">
                  <div className="min">
                    <input
                      type="text"
                      placeholder="Min"
                      onChange={(e) => handleMinAge(e.target.value)}
                    />
                  </div>
                  <span>-</span>
                  <div className="max">
                    <input
                      type="text"
                      placeholder="Max"
                      onChange={(e) => handleMaxAge(e.target.value)}
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item> */}

            <Accordion.Item eventKey="5">
              <Accordion.Header>Date Range</Accordion.Header>
              <Accordion.Body>
                <div className="grade">
                  <div className="min">
                    <label>Start Date</label>
                    <input
                      type="date"
                      placeholder="Min"
                      min={minDate()}
                      value={date}
                      onChange={onDateChange}
                    />
                  </div>
                  <span>-</span>
                  <div className="max">
                    <label>End Date</label>

                    <input
                      type="date"
                      placeholder="Max"
                      min={date}
                      value={endDate}
                      onChange={onEndDateChange}
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="6">
              <Accordion.Header>Days Of Week</Accordion.Header>
              <Accordion.Body>
                <div className="grade">
                  <div className="min selectedInput">
                    <Typeahead
                      className="text-input "
                      id="public-methods-example"
                      labelKey={(options) => options.name}
                      multiple
                      options={days_of_week}
                      placeholder="Select days"
                      selected={selectedDays}
                      onChange={(e) => onSelectDays(e)}
                      // disabled={!createFormData?.start_date_time}
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="7">
              <Accordion.Header>Price</Accordion.Header>
              <Accordion.Body>
                <div className="grade priceG">
                  <div className="min">
                    <input
                      type="text"
                      placeholder="Min"
                      value={startPriceValue ? `$${startPriceValue}` : ""}
                      onChange={(e) => handleStartPrice(e.target.value)}
                    />
                  </div>
                  <span>-</span>
                  <div className="max">
                    <input
                      type="text"
                      placeholder="Max"
                      value={endPriceValue ? `$${endPriceValue}` : ""}
                      onChange={(e) => handleEndPrice(e.target.value)}
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div className="reset-btn">
            <button onClick={handleResetFilter}>Reset Filter</button>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Filter;
