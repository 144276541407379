import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../common/AppContext";
import { GlobalContext } from "../../App";

function GetItNow() {
  const { setShowLoader } = useContext(GlobalContext);

  const [isData, setIsData] = useState([]);

  /**
   * @method [getAdList] to show the listing of the records and according to the keyword also
   
   */

  const getAdList = useCallback(async () => {
    const requestOption = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}ad`,
      requestOption
    );

    if (res.status >= 200 && res.status <= 399) {
      const data = await res.json();
      setIsData(data?.data);
      setShowLoader(false);
    }
    if (res.status === 401) {
      console.log("err");
    }

    if (res.status >= 400 && res.status <= 500) {
      let error = await res.json();
      console.log(error);
      setShowLoader(false);
    }
  }, []);

  useEffect(() => {
    getAdList();
  }, []);

  return (
    <>
      <a
        href={
          isData[0]?.link?.includes("https")
            ? isData[0]?.link
            : `https://${isData[0]?.link}`
        }
        // href={isData[0]?.link}
        target="_blank"
      >
        <div>
          <div
            className="get-it-now"
            // style={{
            //   backgroundImage: `url(${
            //     process.env.REACT_APP_IMAGE_URL + isData[0]?.banner
            //   })`,
            // }}
          >
            <img
              src={process.env.REACT_APP_IMAGE_URL + isData[0]?.banner}
              alt="ad"
            />
            {/* <h2>Marketing Value</h2>
        <p>Put old marketing tactics in timeout</p> */}
            {/* {isData[0]?.link && (
              <button className="get-it-btn">
                {" "}
                Get it now{" "}
                <img
                  src={`${process.env.REACT_APP_LOCAL_IMG}ArrowRight.svg`}
                  alt=""
                />
              </button>
            )} */}
          </div>
        </div>
      </a>
    </>
  );
}

export default GetItNow;
