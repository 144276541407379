// School Grade
export const school_grade_list = [
  {
    value: 1,
    title: "Pre-K",
  },
  {
    value: 2,
    title: "Kindergarten",
  },
  {
    value: 3,
    title: "1st Grade",
  },
  {
    value: 4,
    title: "2nd Grade",
  },
  {
    value: 5,
    title: "3rd Grade",
  },
  {
    value: 6,
    title: "4th Grade",
  },
  {
    value: 7,
    title: "5th Grade",
  },
  {
    value: 8,
    title: "6th Grade",
  },
  {
    value: 9,
    title: "7th Grade",
  },
  {
    value: 10,
    title: "8th Grade",
  },
  {
    value: 11,
    title: "9th Grade",
  },
  {
    value: 12,
    title: "10th Grade",
  },
  {
    value: 13,
    title: "11th Grade",
  },
  {
    value: 14,
    title: "12th Grade",
  },
];

// gender
export const gender = [
  {
    value: 1,
    title: "Male",
  },
  {
    value: 2,
    title: "Female",
  },
  {
    value: 3,
    title: "Co-ed",
  },
  {
    value: 4,
    title: "Anyone",
  },
];

// days_of_week
export const days_of_week = [
  {
    value: "Sunday",
    name: "Sunday",
  },
  {
    value: "Monday",
    name: "Monday",
  },
  {
    value: "Tuesday",
    name: "Tuesday",
  },
  {
    value: "Wednesday",
    name: "Wednesday",
  },
  {
    value: "Thursday",
    name: "Thursday",
  },
  {
    value: "Friday",
    name: "Friday",
  },
  {
    value: "Saturday",
    name: "Saturday",
  },
];
