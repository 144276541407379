import React, { useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axois, { headers } from "../../http/axios";
import { toast } from "react-toastify";
import Spinner from "../../common/Spinner";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ mode: "all" });
  const [showPassword, setShowPassword] = useState({
    newPass: false,
    confirmPass: false,
  });

  const handleResetPassword = (data) => {
    if (data.password !== data.password_confirmation) {
      setError("password_confirmation", { type: "manual" });
      return;
    }

    const reqBody = {
      ...data,
      email: location.state.email,
      token: location.state.otp,
    };

    setLoading(true);
    axois
      .post("auth/password/reset", reqBody, {
        headers: headers,
      })
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
          navigate("/login");
        }, 0);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };
  return (
    <div>
      <div className="form_onboard">
        <h3 className="heading">Reset Password</h3>
        <p className="textp">Please enter your new password</p>
        <div className="form-area">
          <form onSubmit={handleSubmit(handleResetPassword)}>
            <div className="inputGroup mb20">
              <label>New Password</label>
              <div className="inputAdd_icon">
                <input
                  type={showPassword.newPass ? "text" : "password"}
                  className="inputControl"
                  placeholder="Enter New Password"
                  autoComplete="Enter New Password"
                  {...register("password", { required: true })}
                />
                <span class="inputicon">
                  <img
                    src={
                      !showPassword.newPass
                        ? "assets/images/eyehidden.svg"
                        : "assets/images/paasword.svg"
                    }
                    alt="eye-icon"
                    onClick={() =>
                      setShowPassword((pre) => ({
                        ...pre,
                        newPass: !pre.newPass,
                      }))
                    }
                  />
                </span>
              </div>
              {errors.password?.type === "required" && (
                <p role="alert" className="text-danger">
                  New password is required
                </p>
              )}
            </div>
            <div className="inputGroup mb20">
              <label>Confirm Password</label>
              <div className="inputAdd_icon">
                <input
                  type={showPassword.confirmPass ? "text" : "password"}
                  className="inputControl"
                  placeholder="Enter Confirm Password"
                  autoComplete="Enter Confirm Password"
                  {...register("password_confirmation", { required: true })}
                />
                <span class="inputicon">
                  <img
                    src={
                      !showPassword.confirmPass
                        ? "assets/images/eyehidden.svg"
                        : "assets/images/paasword.svg"
                    }
                    alt="eye-icon"
                    onClick={() =>
                      setShowPassword((pre) => ({
                        ...pre,
                        confirmPass: !pre.confirmPass,
                      }))
                    }
                  />
                </span>
              </div>

              {errors.password_confirmation?.type === "required" && (
                <p role="alert" className="text-danger">
                  Confirm password is required
                </p>
              )}
              {errors.password_confirmation?.type === "manual" && (
                <p role="alert" className="text-danger">
                  Password and Confirm password must be same
                </p>
              )}
            </div>
            <div className="btn_sub mt-5">
              <button className="cusbtn w-100">Login</button>
            </div>
          </form>
        </div>
      </div>
      {loading && <Spinner />}
    </div>
  );
};

export default ResetPassword;
