import React, { useCallback, useContext, useEffect } from "react";
import moment from "moment";
import Pagination from "../../pagination/Pagination";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../common/Spinner";
import { GlobalContext } from "../../App";
import Auth from "../auth/Auth";
import { toast } from "react-toastify";
import { trimText } from "../helpers/helper";
import Filter from "../common/Filter";

const Category = () => {
  const {
    setShowLoader,
    showLoader,
    setIsData,
    isData,
    pages,
    setPage,
    setDropLoaction,
  } = useContext(GlobalContext);
  const location = useLocation();
  setDropLoaction(location);

  const navigate = useNavigate();

  /**
   * @method [placeholderHandler] to set placeholder on broken image
   *  @param {event} image event
   * @returns String
   */
  const placeholderHandler = (event) => {
    return (event.target.src = "assets/images/events.png");
  };

  const getEventData = useCallback(async (page, pages, selecteState) => {
    setShowLoader(true);
    const requestOption = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Auth.token()}`,
      },
      withCredentials: false,
    };
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}event-data-home?page=${
        pages ? pages : 1
      }&sortBy=start_date_time${
        selecteState ? `&category_slug=${selecteState}` : ""
      }`,
      requestOption
    );

    if (res.status >= 200 && res.status <= 399) {
      const data = await res.json();
      setIsData(data);
      setShowLoader(false);
    }
    if (res.status === 401) {
      console.log("err");
    }

    if (res.status >= 400 && res.status <= 500) {
      let error = await res.json();
      console.log(error);
      setShowLoader(false);
    }
  }, []);

  /**
   * @method [handleFavorite] favorite post api
   * @returns String
   */

  const handleFavorite = async (id, e) => {
    e.stopPropagation();
    setShowLoader(true);
    const requestOption = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Auth.token()}`,
      },
    };

    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}users/event-data/${id}/favorite`,
      requestOption
    );

    if (res.status >= 200 && res.status <= 399) {
      // const data = await res.json();
      toast.success("Event has been added to favorites", { autoClose: 2000 });
      navigate("/");
      setTimeout(() => {
        // getEventData();
        setShowLoader(false);
      }, 1000);
    }
    if (res.status === 401) {
      localStorage.removeItem("__WTD_WEB__");
      navigate("/");
    }
    if (res.status >= 400 && res.status <= 500) {
      const error = await res.json();
      toast.error(error?.message);
      setShowLoader(false);
    }
  };

  // navigate to detail page
  const handleDetailNavigate = (slug) => {
    navigate(`/events-details/${slug}`);
  };

  useEffect(() => {
    getEventData("", pages, location?.state?.from?.slug);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages, location?.state?.from?.slug]);

  return (
    <>
      <div className="title">
        <div className="t-c">
          <h2>Upcoming Events</h2>
          <p>A view on locally tailored events and activities.</p>
        </div>
        <Filter />
      </div>
      <div className="events">
        {isData?.data?.length > 0 > 0
          ? isData?.data?.map((eventData, index) => {
              const {
                id,
                start_date_time,
                end_date_time,
                banner,
                category,
                event_title,
                slug,
                days_of_week,
                tags,
                featured,
                event_desc,
                is_favorite,
                ticket_early_bird_price,
                ticket_late_price,
                is_free,
              } = eventData;
              return (
                <div key={index} className="box">
                  <div
                    className="navigate"
                    onClick={() => handleDetailNavigate(slug)}
                  >
                    <div className="events-img">
                      <div className="post-date">
                        <span>
                          {!start_date_time
                            ? "N/A"
                            : moment(start_date_time).format("MMM")}
                          <b>
                            {!start_date_time
                              ? "N/A"
                              : moment(start_date_time).format("D")}
                          </b>
                        </span>
                      </div>
                      {featured === 1 && <div className="feature_boader"></div>}
                      <div className="post-images">
                        <div
                          className="heart_icon"
                          onClick={(e) => {
                            handleFavorite(id, e);
                          }}
                        >
                          <img
                            src={
                              is_favorite === false
                                ? "../assets/images/favicon.svg"
                                : "../assets/images/loveLinke.svg"
                            }
                            alt="heart"
                          />
                        </div>
                        {banner ? (
                          <img
                            src={process.env.REACT_APP_IMAGE_URL + banner}
                            alt="img01"
                            onError={placeholderHandler}
                          />
                        ) : (
                          <p>
                            <img
                              src={
                                process.env.REACT_APP_IMAGE_URL +
                                category?.photo
                              }
                              alt=""
                            />
                          </p>
                        )}
                        {/* <p className="podcast">
                      {!category?.name ? "N/A" : category?.name}{" "}
                    </p> */}
                      </div>
                    </div>
                    <div className="events-name">
                      <p>
                        {featured === 1 && (
                          <>
                            <img
                              src={`${process.env.REACT_APP_LOCAL_IMG}feature.svg`}
                              alt=""
                            />{" "}
                            {/* {!category?.name ? "N/A" : category?.name}{" "} */}
                            <b>Featured</b>
                            <span> |</span>
                          </>
                        )}
                        <span>
                          {start_date_time &&
                            moment(start_date_time).format(
                              "MMM Do, YYYY @h:mm A "
                            )}
                          {end_date_time &&
                            moment(end_date_time).format(
                              "- MMM Do, YYYY @h:mm A"
                            )}
                        </span>
                      </p>
                      <h3>{!event_title ? "N/A" : event_title}</h3>
                      {/* <h4>This is a virtual event</h4> */}
                      <h4>{!category?.name ? "N/A" : category?.name} </h4>
                      <span
                        className={`description d-flex
                          ${
                            ""
                            // event_desc?.length > Number(330) ? "d-flex" : ""
                          }`}
                      >
                        {event_desc ? (
                          <div
                            className={`${
                              event_desc?.length > Number(330)
                                ? "text-ellipsis"
                                : ""
                            } `}
                            dangerouslySetInnerHTML={{
                              __html: trimText(event_desc),
                            }}
                          ></div>
                        ) : (
                          <span className="option text-center"> - </span>
                        )}
                        {event_desc?.length > Number(330) && (
                          <span
                            onClick={() => handleDetailNavigate(slug)}
                            className={`primary-txt text-nowrap pointer ${
                              event_desc?.length > Number(330) ? "ps-2" : ""
                            }`}
                            // onClick={handleShow}
                          >
                            See More
                          </span>
                        )}

                        {/* {event_desc?.length > Number(330) ? (
                            <>
                              <p
                                className="desc_event"
                                dangerouslySetInnerHTML={{ __html: event_desc }}
                              ></p>{" "}
                              <a href="#">See More</a>{" "}
                            </>
                          ) : (
                            <div
                              dangerouslySetInnerHTML={{ __html: event_desc }}
                            ></div>
                          )}
                          <div
                            dangerouslySetInnerHTML={{ __html: event_desc }}
                          ></div> */}
                      </span>
                      <div className="event-location">
                        <div className="post-chanrge">
                          {ticket_early_bird_price || ticket_late_price ? (
                            <>
                              <b>
                                {ticket_early_bird_price === null ? (
                                  ""
                                ) : (
                                  <b>{` $${ticket_early_bird_price}`}</b>
                                )}{" "}
                              </b>
                              {ticket_late_price === null ? (
                                ""
                              ) : (
                                <b>
                                  {(ticket_early_bird_price ? "- " : "") +
                                    ` $${ticket_late_price}`}
                                </b>
                              )}
                            </>
                          ) : is_free === 1 || is_free === 0 ? (
                            <b>Free</b>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="post-tag">
                          {tags?.split(",").map((item, idx) => (
                            <p key={idx}>{`#${item}`}</p>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : ""}
      </div>

      {isData?.data?.length > 0 && (
        <div className="dash_pagination col-sm-12">
          <nav aria-label="Page navigation example">
            <Pagination
              totalPages={isData?.last_page}
              currentPage={isData?.current_page}
              page={pages}
              setPage={(page) => setPage(page)}
            />
          </nav>
        </div>
      )}
      {showLoader && <Spinner />}
    </>
  );
};

export default Category;
