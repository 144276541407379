import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { Essentials } from '@ckeditor/ckeditor5-essentials';

function TextEditor({ data, onChange }) {
  return (
    <div className="ck-text-editor">
      <CKEditor
        editor={ClassicEditor}
        data={data}
        className="textarea"
        onChange={onChange}
        config={{
          toolbar: [
            "undo",
            "redo",
            "other",
            "Maximize",
            "|",
            "heading",
            // '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
            "|",
            "bold",
            "italic",
            "strikethrough",
            "subscript",
            "superscript",
            "code",
            "|",
            "link",
            // 'uploadImage', 'blockQuote', 'codeBlock',
            "|",
            "bulletedList",
            "numberedList",
            "todoList",
            "maximize",
            "minimize",
          ],
          // plugins: [Essentials],
        }}
      />
    </div>
  );
}

export default TextEditor;
