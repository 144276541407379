import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { capitalizeString } from "../helpers/helper";
import ShowNowAdds from "../shop-now-add/ShopNowAdds";
import NewsLetter from "../news-letter/NewsLetter";
import GetItNow from "../get-it-now/GetItNow";
import { Modal } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import Spinner from "../common/Spinner";
import { school_grade_list } from "../common/TagArray";
import { GlobalContext } from "../../App";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import ShowHorizontalAd from "../shop-now-add/ShowHorizontalAd";
import ShowSquareAd from "../shop-now-add/ShowSquareAd";

function EventDetails() {
  const location = useLocation();
  const { setShowLoader, showLoader, selecteState, isData, pages, setPage } =
    useContext(GlobalContext);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleEvent, setIsModalVisibleEvent] = useState(false);
  const [eventData, setEventData] = useState({});
  const [imageIndex, setImageIndex] = useState(0);

  const { slug } = useParams();
  const navigate = useNavigate();

  console.log(slug, "slug");

  //   Handle modal Image show index
  const handleImage = (id) => {
    setImageIndex(id);
  };

  /**
   * @method [getSingleEventBySlug] to show the listing of the records and according to the keyword also
   
   */

  const getSingleEventBySlug = useCallback(async () => {
    setShowLoader(true);
    const requestOption = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}event-data/${slug}`,
      requestOption
    );

    if (res.status >= 200 && res.status <= 399) {
      const data = await res.json();
      setEventData(data?.data);
      window.scrollTo(0, 0);
      setShowLoader(false);
    }
    if (res.status === 401) {
      console.log("err");
      navigate("/");
    }

    if (res.status >= 400 && res.status <= 500) {
      let error = await res.json();
      console.log(error);
      navigate("/");
      setShowLoader(false);
    }
  }, []);

  // ✅ Find the first object that matches a condition
  const foundFormGrade = school_grade_list.find((obj) => {
    // return console.log(
    //   obj.value,
    //   Number(eventData?.school_grade_start),
    //   "valalalalla"
    // );
    return obj.value === Number(eventData?.school_grade_start);
  });

  console.log(foundFormGrade?.title, "foundFormGrade");

  // ✅ Find the first object that matches a condition
  const foundToGrade = school_grade_list.find((obj) => {
    return obj.value === Number(eventData?.school_grade_end);
  });

  useEffect(() => {
    getSingleEventBySlug();
  }, []);

  console.log(eventData?.banner, "eventData?.banner");
  return (
    <>
      <div
        className="eventdetails"
        style={{
          backgroundImage: `url(${process.env.REACT_APP_LOCAL_IMG}eventdetails.png)`,
        }}
      >
        <div className="container">
          <div className="logo">
            <Link to="/">
              {" "}
              <img
                src={`${process.env.REACT_APP_LOCAL_IMG}leftarrow.png`}
                alt=""
              />
            </Link>
            <img src={`${process.env.REACT_APP_LOCAL_IMG}wtdusvi.svg`} alt="" />
          </div>

          <div className="event-grid-section">
            <div
              className="event-banner"
              // style={{
              //   backgroundImage: `url(${
              //     eventData?.banner
              //       ? process.env.REACT_APP_IMAGE_URL + eventData?.banner
              //       : process.env.REACT_APP_IMAGE_URL +
              //         eventData?.category?.photo
              //   })`,
              // }}
              onClick={() => {
                setIsModalVisible(true);
              }}
            >
              <div className="banner-content">
                <div className="post-date">
                  <span>
                    {!eventData?.start_date_time
                      ? "N/A "
                      : moment(eventData?.start_date_time).format("MMM")}
                    <b>
                      {!eventData?.start_date_time
                        ? "N/A "
                        : moment(eventData?.start_date_time).format("D")}
                    </b>
                  </span>
                </div>
                <div className="post-content">
                  <h3>
                    {!eventData?.event_title ? "N/A " : eventData?.event_title}
                  </h3>

                  <p>
                    Category:{" "}
                    {!eventData?.category?.name
                      ? "N/A "
                      : eventData?.category?.name}
                  </p>
                  <div className="feature-tab">
                    <p>
                      {eventData?.featured === 1 && (
                        <>
                          <img
                            src={`${process.env.REACT_APP_LOCAL_IMG}Bookmark.svg`}
                            alt=""
                          />{" "}
                          {/* {!category?.name ? "N/A" : category?.name}{" "} */}
                          <b>Featured</b>
                        </>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="event-image">
                <img
                  src={
                    eventData?.banner
                      ? process.env.REACT_APP_IMAGE_URL + eventData?.banner
                      : process.env.REACT_APP_IMAGE_URL +
                        eventData?.category?.photo
                  }
                  alt="event banner"
                />
              </div>
            </div>
            <div className="event-picture">
              {eventData?.event_pictures?.map((img, index) => {
                return (
                  <div className="picture-box" key={index}>
                    <img
                      src={process.env.REACT_APP_IMAGE_URL + img}
                      alt=""
                      onClick={() => {
                        setIsModalVisibleEvent(true);
                        handleImage(index);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/* <a
        // href={`https://www.facebook.com/sharer.php?u=${window.location.href}`}
        href={`https://www.facebook.com/sharer/sharer.php?u=${
          process.env.REACT_APP_IMAGE_URL + eventData?.category?.photo
        }&t=${window.location.href}`}
        target="_blank"
        rel="noreferrer"
      >
        FB
      </a> */}
      <div className="seacription">
        <div className="container d-flex">
          <div className="left">
            <h2>Description</h2>
            <div className="desc-area">
              {!eventData?.event_desc ? (
                "N/A "
              ) : (
                <p
                  dangerouslySetInnerHTML={{ __html: eventData?.event_desc }}
                ></p>
              )}
            </div>
            <h2>Event Details</h2>

            <div className="grad-ent">
              {moment(eventData?.start_date_time).format("MMMM, Do YYYY ") ===
              moment(eventData?.end_date_time).format("MMMM, Do YYYY ")
                ? (eventData?.start_date_time || eventData?.end_date_time) && (
                    <div className="date-time">
                      <p>
                        <span className="date_span">Start Date: </span>
                        <b>
                          {moment(eventData?.start_date_time).format(
                            "MMM, Do YYYY"
                          )}
                        </b>
                      </p>
                      <p>
                        <span>Time: </span>
                        <b>
                          {moment(eventData?.start_date_time).format(
                            "h:mm A - "
                          )}
                          {moment(eventData?.end_date_time).format("h:mm A")}
                        </b>
                      </p>
                    </div>
                  )
                : (eventData?.start_date_time || eventData?.end_date_time) && (
                    <>
                      <div className="date-time">
                        <p>
                          <span className="date_span">Start date & time:</span>
                          <b>
                            {moment(eventData?.start_date_time).format(
                              "MMM Do, YYYY"
                            )}
                          </b>
                        </p>
                        <p>
                          <span>Time: </span>
                          <b>
                            {moment(eventData?.start_date_time).format(
                              "h:mm A"
                            )}
                          </b>
                        </p>
                      </div>
                      {eventData?.end_date_time && (
                        <div className="date-time">
                          <p>
                            <span className="date_span">End date & time:</span>
                            <b>
                              {moment(eventData?.end_date_time).format(
                                "MMM Do, YYYY"
                              )}
                            </b>
                          </p>
                          <p>
                            <span>Time: </span>
                            <b>
                              {moment(eventData?.end_date_time).format(
                                "h:mm A"
                              )}
                            </b>
                          </p>
                        </div>
                      )}
                    </>
                  )}

              {eventData?.address && (
                <p>
                  <span>Venue: </span>
                  <b>{eventData?.address}</b>
                </p>
              )}
            </div>

            {eventData?.gender && (
              <p>
                <span>Gender: </span>
                <b>{eventData?.gender}</b>
              </p>
            )}

            {eventData?.all_age !== 0 ? (
              <p>
                <span>Age:</span> <b>All ages welcome</b>
              </p>
            ) : (
              (eventData?.age_range_from || eventData?.age_range_to) && (
                <p>
                  <span>Age :</span>{" "}
                  <b>
                    {eventData?.age_range_from &&
                      eventData?.age_range_from + " yrs "}
                    {eventData?.age_range_to && "-"}
                    {eventData?.age_range_to &&
                      eventData?.age_range_to + " yrs"}
                  </b>
                </p>
              )
            )}

            <div className="grad-ent">
              {(foundFormGrade?.title || foundToGrade?.title) && (
                <p>
                  <span>Grade: </span>
                  <b>
                    {foundFormGrade?.title} through {foundToGrade?.title}
                  </b>
                </p>
              )}
            </div>

            {eventData?.registration_fee && (
              <p>
                <span>Registration Fee: </span>
                <b>${eventData?.registration_fee}</b>
              </p>
            )}

            <div className="grad-ent">
              {eventData?.ticket_early_bird_price ||
              eventData?.ticket_late_price ? (
                <p>
                  <span>Cost: </span>
                  <b>
                    {eventData?.ticket_early_bird_price === null ? (
                      ""
                    ) : (
                      <b>{`$${eventData?.ticket_early_bird_price}`}</b>
                    )}
                  </b>
                  {eventData?.ticket_late_price === null ? (
                    ""
                  ) : (
                    <b>
                      {(eventData?.ticket_early_bird_price ? "- " : "") +
                        `$${eventData?.ticket_late_price}`}
                    </b>
                  )}
                </p>
              ) : eventData?.is_free === 1 ? (
                <p>
                  <span>
                    Cost:
                    <b>Free</b>
                  </span>
                </p>
              ) : (
                ""
              )}
            </div>

            <div className="grad-ent">
              {eventData?.contact_name && (
                <div className="contact-details">
                  <div className="name">
                    <p>
                      <span>Contact: </span>
                      <b>{capitalizeString(eventData?.contact_name)}</b>
                    </p>
                  </div>
                </div>
              )}
              {eventData?.email && (
                <div className="contact-details">
                  <div className="name">
                    <p>
                      <span>Email: </span>
                      <b>{eventData?.email}</b>
                    </p>
                  </div>
                </div>
              )}
              {eventData?.phone && (
                <div className="name">
                  <p>
                    <span>Phone: </span>
                    <b>{eventData?.phone}</b>
                  </p>
                </div>
              )}
              {(eventData?.social_facebook ||
                eventData?.social_twitter ||
                eventData?.social_instagram ||
                eventData?.social_linkedIn) && (
                <p>
                  <span>Social Links:</span>
                  <span className="social-link">
                    {eventData?.social_facebook && (
                      <a href={eventData?.social_facebook} target="_blank">
                        <img
                          src={`${process.env.REACT_APP_LOCAL_IMG}facebook.svg`}
                          alt=""
                        />
                      </a>
                    )}
                    {eventData?.social_twitter && (
                      <a href={eventData?.social_twitter} target="_blank">
                        <img
                          src={`${process.env.REACT_APP_LOCAL_IMG}twitter2.svg`}
                          alt=""
                        />
                      </a>
                    )}
                    {eventData?.social_instagram && (
                      <a href={eventData?.social_instagram} target="_blank">
                        {" "}
                        <img
                          src={`${process.env.REACT_APP_LOCAL_IMG}instagram2.svg`}
                          alt=""
                        />
                      </a>
                    )}

                    {eventData?.social_linkedIn && (
                      <a href={eventData?.social_linkedIn} target="_blank">
                        <img
                          src={`${process.env.REACT_APP_LOCAL_IMG}linkdin.svg`}
                          alt=""
                        />
                      </a>
                    )}
                  </span>
                </p>
              )}
            </div>

            {eventData?.tags && (
              <div className="tags-details">
                <p>Event Tags:</p>
                <div className="tags-tab">
                  {eventData?.tags?.split(",").map((item, index) => (
                    <span key={index}>{`#${item}`}</span>
                  ))}
                </div>
              </div>
            )}
            <div className="disc-buttons">
              <a href={eventData?.purchase_url} target="_blank">
                <button className="ticket">Learn more about this event</button>
              </a>
              <a
                // href={`https://www.facebook.com/sharer.php?u=${window.location.href}`}
                href={`https://www.facebook.com/sharer.php?u=${window.location.href}`}
                target="_blank"
                rel="noreferrer"
              >
                <button className="ticket_fb">
                  <img
                    src={`${process.env.REACT_APP_LOCAL_IMG}sharefb.svg`}
                    alt=""
                  />
                  Share on facebook
                </button>
              </a>
            </div>
            <div className="mt-5">
              <ShowHorizontalAd />
            </div>
          </div>
          <div className="riight">
            <ShowNowAdds />
            <NewsLetter />
            <GetItNow />
          </div>
        </div>
      </div>

      {/* <Footer /> */}

      {/* Modal banner section start */}
      <Modal
        show={isModalVisible}
        centered
        onHide={() => setIsModalVisible(false)}
        className="image-modal"
        size="lg"
      >
        <Modal.Header className="modal-header-claim" closeButton></Modal.Header>

        <img
          src={
            eventData?.banner
              ? process.env.REACT_APP_IMAGE_URL + eventData?.banner
              : process.env.REACT_APP_IMAGE_URL + eventData?.category?.photo
          }
          alt="img01"
        />
      </Modal>
      {/* Modal banner section end */}

      {/* Modal event imgae section start */}
      <Modal
        show={isModalVisibleEvent}
        centered
        onHide={() => setIsModalVisibleEvent(false)}
        className="image-modal"
        size="lg"
      >
        <Modal.Header className="modal-header-claim" closeButton></Modal.Header>

        <Carousel
          slide="false"
          indicators={false}
          defaultActiveIndex={imageIndex}
        >
          {eventData?.event_pictures?.map((img, index) => {
            return (
              <Carousel.Item key={index} className="carousel-item-claim">
                <div className="modal-picture-box" key={index}>
                  <img src={process.env.REACT_APP_IMAGE_URL + img} alt="" />
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </Modal>
      {/* Modal event imgae section end */}
      {showLoader && <Spinner />}
    </>
  );
}

export default EventDetails;
