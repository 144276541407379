import React, { useEffect } from "react";
import { loadScript } from "../helpers/helper";
var divStyle = { display: "block" };

function ShowNowAdds() {
  useEffect(() => {
    loadScript(
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3571706419332962"
    );
    loadScript("", " (adsbygoogle = window.adsbygoogle || []).push({});");
  }, []);
  return (
    <>
      <div className="shop-now">
        {/* <img src="assets/images/shopnow.png" alt="" /> */}

        <ins
          className="adsbygoogle"
          style={divStyle}
          data-ad-client="ca-pub-3571706419332962"
          data-ad-slot="4941144298"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </div>
    </>
  );
}

export default ShowNowAdds;
