import "./App.scss";
import {
  HashRouter,
  BrowserRouter as Router,
  Route,
  Routes,
  createHashRouter,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Home from "./component/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import EventDetails from "./component/events/EventDetails";
import { createContext, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import Category from "./component/category/Category";
import Events from "./component/events/Events";
import DashboardLayout from "./component/layout/DashboardLayout";
import DetailLayout from "./component/layout/DetailLayout";

import { Helmet } from "react-helmet";
import Login from "./component/onboarding/login/Login";
import ForgotPassword from "./component/onboarding/forgotpassword/ForgotPassword";
import Verification from "./component/onboarding/verification/Verification";
import ResetPassword from "./component/onboarding/resetpassword/ResetPassword";
import OnboardLayout from "./component/layout/OnboardLayout";
import { loadScript } from "./component/helpers/helper";
import SignUp from "./component/onboarding/signup/SignUp";
import EventLayout from "./component/layout/EventLayout";
import CreateEvent from "./component/createevent/CreateEvent";
import Favorites from "./component/favorites/Favorites";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe("pk_test_wDlYbBsqPdfD9OfHS84AeZhS");

export const GlobalContext = createContext(null);

export const router = createHashRouter([
  {
    path: "",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Events />,
      },

      {
        path: "category/:slug",
        element: <Category />,
      },
    ],
  },
  {
    path: "",
    element: <DetailLayout />,
    children: [
      {
        path: "events-details/:slug",
        element: <EventDetails />,
      },
    ],
  },
  {
    path: "",
    element: <OnboardLayout />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "signup",
        element: <SignUp />,
      },
      {
        path: "forgot_password",
        element: <ForgotPassword />,
      },
      {
        path: "verification_code",
        element: <Verification />,
      },
      {
        path: "reset_password",
        element: <ResetPassword />,
      },
    ],
  },

  {
    path: "",
    element: <EventLayout />,
    children: [
      {
        path: "create-event",
        element: <CreateEvent />,
      },
      {
        path: "favorites",
        element: <Favorites />,
      },
    ],
  },
]);

export const browserRoute = createBrowserRouter([
  {
    path: "",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Events />,
      },

      {
        path: "category/:slug",
        element: <Category />,
      },
    ],
  },
  {
    path: "",
    element: <DetailLayout />,
    children: [
      {
        path: "events-details/:slug",
        element: <EventDetails />,
      },
    ],
  },
  {
    path: "",
    element: <OnboardLayout />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "signup",
        element: <SignUp />,
      },
      {
        path: "forgot_password",
        element: <ForgotPassword />,
      },
      {
        path: "verification_code",
        element: <Verification />,
      },
      {
        path: "reset_password",
        element: <ResetPassword />,
      },
    ],
  },

  {
    path: "",
    element: <EventLayout />,
    children: [
      {
        path: "create-event",
        element: <CreateEvent />,
      },
      {
        path: "favorites",
        element: <Favorites />,
      },
    ],
  },
]);

function App() {
  const [showLoader, setShowLoader] = useState(true);
  const [selecteState, setSelecteState] = useState("");
  const [isData, setIsData] = useState([]);
  const [dropLoaction, setDropLoaction] = useState("");
  const [searchHandle, setSearchHandle] = useState("");
  const [isDropData, setIsDropData] = useState([]);
  const [selectEvent, setSelectEvent] = useState([]);
  const [feature, setFeature] = useState("");
  const [selectEventGender, setSelectEventGender] = useState(
    JSON.parse(localStorage?.getItem("genderCheckBox")) || []
  );
  const [minAge, setMinAge] = useState(localStorage.getItem("MinAge") || "");
  const [maxAge, setMaxAge] = useState(
    JSON.parse(localStorage?.getItem("MaxAge")) || ""
  );
  const [selected, setSelected] = useState(
    JSON.parse(localStorage?.getItem("selectEventGradeFrom")) || ""
  );
  const [selectedGradeTo, setSelectedGradeTo] = useState(
    JSON.parse(localStorage?.getItem("selectEventGradeTo")) || ""
  );
  const [date, setDate] = useState(
    JSON.parse(localStorage?.getItem("start_date")) || ""
  );
  console.log({ date });
  const [endDate, setEndDate] = useState(
    JSON.parse(localStorage?.getItem("end_date")) || ""
  );
  const [daysMulti, setDaysMulti] = useState();
  const [selectedDays, setSelectedDays] = useState(
    JSON.parse(localStorage?.getItem("days_of_week")) || ""
  );
  const [startPriceValue, setStartPriceValue] = useState(
    JSON.parse(localStorage?.getItem("startPriceValue")) || ""
  );
  const [endPriceValue, setEndPriceValue] = useState(
    JSON.parse(localStorage?.getItem("endPriceValue")) || ""
  );

  const [pages, setPage] = useState(1);

  // useEffect(() => {
  //   loadScript(
  //     "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3571706419332962"
  //   );
  //   loadScript("", " (adsbygoogle = window.adsbygoogle || []).push({});");
  // }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Elements stripe={stripePromise}>
        <GlobalContext.Provider
          value={{
            setShowLoader,
            showLoader,
            setSelecteState,
            selecteState,
            isData,
            setIsData,
            pages,
            setPage,
            dropLoaction,
            setDropLoaction,
            setSearchHandle,
            searchHandle,
            isDropData,
            setIsDropData,
            selectEvent,
            setSelectEvent,
            setFeature,
            feature,
            setSelectEventGender,
            selectEventGender,
            minAge,
            setMinAge,
            maxAge,
            setMaxAge,
            selected,
            setSelected,
            selectedGradeTo,
            setSelectedGradeTo,
            date,
            setDate,
            endDate,
            setEndDate,
            daysMulti,
            setDaysMulti,
            selectedDays,
            setSelectedDays,
            startPriceValue,
            setStartPriceValue,
            endPriceValue,
            setEndPriceValue,
          }}
        >
          <RouterProvider
            router={
              process.env.REACT_APP_BASE_URL ===
              "http://103.149.154.53/WTDUSVI/api/"
                ? router
                : browserRoute
            }
          />
        </GlobalContext.Provider>
      </Elements>
    </>
  );
}

export default App;
