import React from "react";
import Header from "../header/Header";
import { Navigate, Outlet } from "react-router-dom";
import Footer from "../footer/Footer";
import Auth from "../auth/Auth";

const EventLayout = () => {
  return (
    <div>
      <Header />
      <section className="upcoming-event">
        <div className="container event-box">
          {/* {Auth.isUserAuthenticated() ? <Navigate to="/login" /> : <Outlet />} */}
          <Outlet />
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default EventLayout;
