import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axois, { headers } from "../../http/axios";
import OtpInput from "react-otp-input";
import Spinner from "../../common/Spinner";

const Verification = () => {
  const [otpValue, setOtp] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  /**
   * @method resendCode
   * @param {Event} event
   */
  const resendCode = (event) => {
    event.preventDefault();
    setLoading(true);
    axois
      .post("auth/password/forgot", {
        email: location.state.email,
        // role: "admin",
      })
      .then((res) => {
        // setTimeout(() => {
        setLoading(false);
        //   setMinutes(1);
        //   setSeconds(59);
        // }, 0);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
        console.log(err);
      });
  };

  /**
   * @method [verificationOtp] use to verify the otp that has been recieved in email
   * @param {Event} event
   * @returns
   */

  const verificationOtp = (event) => {
    // if (!isValid()) return;
    // if (otpValue === undefined) {
    //   return;
    // }
    event.preventDefault();
    // if (!stopRedirection) {
    if (!otpValue || otpValue.length < 6) {
      toast.error("OTP is not valid.");
      return;
    }
    setLoading(true);
    axois
      .post(
        "auth/password/validate/token",
        {
          email: location.state.email,
          // role: "admin",
          token: otpValue,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
          navigate("/reset_password", {
            state: { email: location.state.email, otp: otpValue },
          });
        }, 0);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });
    // }
  };

  return (
    <div>
      <div className="form_onboard">
        <h3 className="heading">Enter OTP</h3>
        <p className="textp">Please enter your code</p>
        <div className="form-area">
          <form>
            <div className="inputGroup mb20">
              <label>Enter OTP</label>
              <div className="multipleInput">
                <OtpInput
                  value={otpValue}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span> </span>}
                  renderInput={(props) => (
                    <input {...props} className="inputControl" />
                  )}
                />
              </div>
            </div>

            <div className="btn_sub mt-5">
              <button className="cusbtn w-100" onClick={verificationOtp}>
                Verify & Proceed
              </button>
            </div>
            <p className="textp btxt text-center mt-4">
              <span to="signup" className="linkOn" onClick={resendCode}>
                Resend OTP
              </span>
            </p>
          </form>
        </div>
      </div>
      {loading && <Spinner />}
    </div>
  );
};

export default Verification;
