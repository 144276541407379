import React, { useContext, useState } from "react";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { GlobalContext } from "../../App";
import { Modal } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import Auth from "../auth/Auth";
import { toast } from "react-toastify";

function Header() {
  const { setSearchHandle, setShowLoader } = useContext(GlobalContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    localStorage.clear();
    navigate("/login");
    return;
    setShowLoader(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Auth.token()}`,
      },
    };
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}auth/logout`,
      requestOptions
    );
    if (response.status >= 200 && response.status <= 399) {
      const data = await response.json();
      setShowLoader(false);
      Auth.logout();
      setIsModalVisible(true);
      navigate("/");
      toast.success(data.message);
    }

    if (response.status === 401) {
      localStorage.removeItem("__WTD_WEB__");
      navigate("/");
    }
    if (response.status >= 400 && response.status <= 500) {
      const error = await response.json();
      toast.error(error.message);
      setShowLoader(false);
    }
  };

  const handleEvent = (eventName) => {
    localStorage.setItem("eventName", eventName);
  };
  return (
    <>
      <section className="header">
        <div className="container">
          <Navbar expand="lg">
            {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="menu-iten">
                <NavLink to="/">Home</NavLink>
                {/* <NavLink to="abc">About us</NavLink> */}
                {!Auth?.token() ? (
                  <NavLink
                    to="/login"
                    onClick={() => handleEvent("/create-event")}
                  >
                    Submit An Event
                  </NavLink>
                ) : (
                  <NavLink to="/create-event">Submit An Event</NavLink>
                )}
                {!Auth?.token() ? (
                  <NavLink
                    to="/login"
                    onClick={() => handleEvent("/favorites")}
                  >
                    Favorites
                  </NavLink>
                ) : (
                  <NavLink to="/favorites">Favorites</NavLink>
                )}

                <a href="https://wtdusvi.com/blog/" style={{ display: "none" }}>
                  Blog
                </a>
                {/* <Nav.Link href="#link">Submit an event</Nav.Link>
                <Nav.Link href="#link">Advertise</Nav.Link> */}
              </Nav>
            </Navbar.Collapse>

            {/* {!window.location.href.includes("favorites") ? (
              <div className="search-box">
                <img
                  src={`${process.env.REACT_APP_LOCAL_IMG}search.svg`}
                  alt=""
                />
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchHandle(e.target.value)}
                />
              </div>
            ) : (
              ""
            )} */}

            <Nav className="menu-iten social">
              {Auth.token() && (
                <div
                  className="logout_btn"
                  onClick={() => {
                    setIsModalVisible(true);
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_LOCAL_IMG}logout.svg`}
                    alt=""
                  />
                </div>
              )}

              {/* <Nav.Link href="#home">
                <img
                  src={`${process.env.REACT_APP_LOCAL_IMG}twitter.svg`}
                  alt=""
                />
              </Nav.Link>
              <Nav.Link href="#home">
                <img
                  src={`${process.env.REACT_APP_LOCAL_IMG}instagram.svg`}
                  alt=""
                />
              </Nav.Link>
              <Nav.Link href="#home">
                <img
                  src={`${process.env.REACT_APP_LOCAL_IMG}tiktok.svg`}
                  alt=""
                />
              </Nav.Link>
              <Nav.Link href="#home">
                <img
                  src={`${process.env.REACT_APP_LOCAL_IMG}youtube.svg`}
                  alt=""
                />
              </Nav.Link> */}
            </Nav>
          </Navbar>
        </div>
      </section>

      {/* Modal banner section start */}
      <Modal
        show={isModalVisible}
        centered
        onHide={() => setIsModalVisible(false)}
        className="image-modal"
      >
        <Modal.Header className="modal-header-claim" closeButton></Modal.Header>
        <div className="modal_body">
          <div className="big_icon">
            <img
              src={`${process.env.REACT_APP_LOCAL_IMG}logouticon.svg`}
              alt="img info"
              className="info_modal"
            />
          </div>
          {/* <h4 className='head4 text-center'>Are you sure?</h4> */}
          <p className="txt text-center mt-3">
            Are you sure you want to logout?
          </p>
          <div className="modal_footerB">
            <button
              className="cusbtn modalbtn"
              onClick={() => setIsModalVisible(false)}
            >
              No
            </button>
            <button
              className="cusbtn modalbtn bg-trn_bnt"
              onClick={handleLogout}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
      {/* Modal banner section end */}
    </>
  );
}

export default Header;
