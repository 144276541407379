import React, { useContext, useRef, useState } from "react";

import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios, { headers } from "../../http/axios";
import { useNavigate } from "react-router-dom";
import Auth from "../../auth/Auth";
import { toast } from "react-toastify";
import Spinner from "../../common/Spinner";
import ReCAPTCHA from "react-google-recaptcha";

function SignUp() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm({ mode: "all" });
  const captchaRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState(false);
  const [initialValues, setInitialValues] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const onSubmit = (data) => {
    // data.stay_in = 1;
    if (data.password !== data.password_confirmation) {
      setError("password_confirmation", { type: "manual" });
      return;
    }
    data["g-recaptcha-response"] = initialValues?.captcha_key;

    setLoading(true);
    axios
      .post("auth/signup", data, {
        headers: headers,
      })
      .then((res) => {
        Auth.login(res.data);
        // localStorage.setItem("userName", res?.data?.user?.first_name);
        setTimeout(() => {
          setLoading(false);
          navigate("/");
        }, 0);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    const subscription = watch(
      (value, { name, type }) => setValues(value)
      // console.log(value, name, type)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <div>
      <div className="form_onboard">
        <h3 className="heading">Sign Up</h3>
        <p className="textp">Please enter your details</p>
        <div className="form-area">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="inputGroup mb20">
              <label>
                Name<sup>*</sup>
              </label>
              <input
                type="text"
                className="inputControl"
                placeholder="Enter  Name"
                autoComplete="Enter  Name"
                {...register("first_name", { required: true })}
              />
            </div>
            {errors.first_name?.type === "required" && (
              <p role="alert" className="text-danger">
                Name is required
              </p>
            )}
            <div className="inputGroup mb20">
              <label>
                Email<sup>*</sup>
              </label>
              <div className="inputAdd_icon">
                <input
                  type="email"
                  className="inputControl"
                  placeholder="Enter  Email"
                  autoComplete="Enter  Email"
                  {...register("email", {
                    required: true,
                    pattern: /\S+@\S+\.\S+/,
                  })}
                />
                <span class="inputicon">
                  <img src="assets/images/atrate_icon.svg" alt="icon" />
                </span>
              </div>
              {errors.email?.type === "required" && (
                <p role="alert" className="text-danger">
                  Email is required
                </p>
              )}
              {errors.email?.type === "pattern" && (
                <p role="alert" className="text-danger">
                  Incorrect email format
                </p>
              )}
            </div>
            <div className="inputGroup mb20">
              <label>
                Password<sup>*</sup>
              </label>
              <div className="inputAdd_icon">
                <input
                  type={showPassword ? "text" : "password"}
                  className="inputControl"
                  placeholder="Enter Password"
                  autoComplete="Enter Password"
                  {...register("password", { required: true })}
                />
                <span class="inputicon">
                  <img
                    src={
                      !showPassword
                        ? "assets/images/eyehidden.svg"
                        : "assets/images/paasword.svg"
                    }
                    alt="icon"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </span>
              </div>
              {errors.password?.type === "required" && (
                <p role="alert" className="text-danger">
                  Password is required
                </p>
              )}
            </div>
            <div className="inputGroup mb20">
              <label>
                Confirm Password<sup>*</sup>
              </label>
              <div className="inputAdd_icon">
                <input
                  type={confirmPass ? "text" : "password"}
                  className="inputControl"
                  placeholder="Enter Password"
                  autoComplete="Enter Password"
                  {...register("password_confirmation", { required: true })}
                />
                <span class="inputicon">
                  <img
                    src={
                      !confirmPass
                        ? "assets/images/eyehidden.svg"
                        : "assets/images/paasword.svg"
                    }
                    alt="icon"
                    onClick={() => setConfirmPass(!confirmPass)}
                  />
                </span>
              </div>
              {errors.password_confirmation && (
                <p crole="alert" className="text-danger">
                  Confirm Password is required
                </p>
              )}
              {errors.password_confirmation?.type === "manual" && (
                <p role="alert" className="text-danger">
                  Password and Confirm password must be same
                </p>
              )}
              {/* {errors.password_repeat?.type !== "required" &&
                values?.password !== values?.password_repeat && (
                  <p crole="alert" className="text-danger">
                    Confirm-Password does not match
                  </p>
                )} */}
            </div>

            <div className="captcha-area">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={captchaRef}
                onChange={(value) =>
                  setInitialValues((pre) => ({ ...pre, captcha_key: value }))
                }
              />
            </div>

            <div className="btn_sub mt-5">
              <button className="cusbtn w-100">Sign Up</button>
            </div>
            <p className="textp btxt text-center mt-4">
              Already have an account ?{" "}
              <Link to="/login" className="linkOn">
                Login
              </Link>
            </p>
          </form>
        </div>
      </div>
      {loading && <Spinner />}
    </div>
  );
}

export default SignUp;
