import React, { useContext, useRef, useState } from "react";

import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios, { headers } from "../../http/axios";
import { useNavigate } from "react-router-dom";
import Auth from "../../auth/Auth";
import { toast } from "react-toastify";
import Spinner from "../../common/Spinner";
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });
  const captchaRef = useRef(null);

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState("");

  const onSubmit = (data) => {
    data.stay_in = 1;

    data["g-recaptcha-response"] = initialValues?.captcha_key;

    setLoading(true);
    axios
      .post("auth/login", JSON.stringify(data), {
        headers: headers,
      })
      .then((res) => {
        Auth.login(res.data);
        setTimeout(() => {
          setLoading(false);

          if (Auth?.token) {
            return navigate(localStorage.getItem("eventName")) || navigate("/");
          } else {
            navigate("/");
          }
        }, 0);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="form_onboard">
        <h3 className="heading">Login</h3>
        <p className="textp">Please enter your details</p>
        <div className="form-area">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="inputGroup mb20">
              <label>Email</label>
              <div className="inputAdd_icon">
                <input
                  type="email"
                  className="inputControl"
                  placeholder="Enter  Email"
                  autoComplete="Enter  Email"
                  {...register("email", {
                    required: true,
                    pattern: /\S+@\S+\.\S+/,
                  })}
                />
                <span class="inputicon">
                  <img src="assets/images/atrate_icon.svg" alt="icon" />
                </span>
                {errors.email?.type === "required" && (
                  <p role="alert" className="text-danger">
                    Email is required
                  </p>
                )}
                {errors.email?.type === "pattern" && (
                  <p role="alert" className="text-danger">
                    Incorrect email format
                  </p>
                )}
              </div>
            </div>
            <div className="inputGroup mb20">
              <label>Password</label>
              <div className="inputAdd_icon">
                <input
                  type={showPassword ? "text" : "password"}
                  className="inputControl"
                  placeholder="Enter Password"
                  autoComplete="Enter Password"
                  {...register("password", { required: true })}
                />
                <span class="inputicon">
                  <img
                    src={
                      !showPassword
                        ? "assets/images/eyehidden.svg"
                        : "assets/images/paasword.svg"
                    }
                    alt="icon"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </span>
              </div>
              {errors.password?.type === "required" && (
                <p role="alert" className="text-danger">
                  Password is required
                </p>
              )}

              <div className="next_link text-end mt-2">
                <Link to="/forgot_password" className="linkOn">
                  Forgot Password?
                </Link>
              </div>

              <div className="captcha-area">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  ref={captchaRef}
                  onChange={(value) =>
                    setInitialValues((pre) => ({ ...pre, captcha_key: value }))
                  }
                />
              </div>
            </div>
            <div className="btn_sub mt-5">
              <button className="cusbtn w-100" type="submit">
                Login
              </button>
            </div>
            <p className="textp btxt text-center mt-4">
              Don’t have an account ?{" "}
              <Link to="/signup" className="linkOn">
                Sign Up
              </Link>
            </p>
          </form>
        </div>
      </div>
      {loading && <Spinner />}
    </div>
  );
};

export default Login;
