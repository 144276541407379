import React, { useState } from "react";
// import leftArrow from "../../assets/images/leftArrow.png";
// import rightArrow from "../../assets/images/rightArrow.png";

const Pagination = ({ totalPages, currentPage, page, setPage, lastPage }) => {
  localStorage.setItem("storepage", page);
  const getPage = localStorage.getItem("storepage");

  const [counter, setCounter] = useState(1);
  //PAGINATION FUNCTION
  function Pagination() {
    let links = [],
      link;

    for (let i = counter; i <= totalPages; i++) {
      link =
        i <= counter + 4 ? (
          <li
            className={`page-item ${i === currentPage ? "active" : ""}`}
            key={i}
          >
            <button className="page-link" onClick={() => setPage(i)}>
              {i}
            </button>
          </li>
        ) : i === counter + 10 ? (
          <button
            key={i}
            className="page-link"
            onClick={() => {
              // setCounter((prevCount) => prevCount + 1);
              // setPage((prevPage) => prevPage + 1);
              setCounter((prevCount) => prevCount + 5);
              setPage((prevPage) => prevPage + 5);
            }}
          >
            ...
          </button>
        ) : null;

      links.push(link);
      continue;
    }
    return links;
  }

  return (
    <nav className="">
      <ul className="pagination">
        <li className="page-item">
          <button
            className="page-link"
            aria-label="Previous"
            onClick={() => {
              setPage(page - 1);

              if (currentPage === counter && currentPage > 5) {
                setCounter(currentPage - 5);
              }
            }}
            disabled={page === 1}
          >
            <span aria-hidden="true">
              <img
                src={`${process.env.REACT_APP_LOCAL_IMG}fast-forward.svg`}
                alt="previous"
              />
            </span>
          </button>
        </li>

        {Pagination()}
        {
          totalPages < 5 ? "" : ""
          // <button className="page-item" onClick={() => setPage(lastPage)}>
          //   {lastPage}
          // </button>
        }

        <li className="page-item">
          <button
            className="page-link"
            aria-label="Next"
            onClick={() => {
              setPage(page + 1);

              if (currentPage === counter + 4) {
                setCounter(currentPage + 1);
              }
            }}
            disabled={+page >= +totalPages}
          >
            <span aria-hidden="true">
              <img
                src={`${process.env.REACT_APP_LOCAL_IMG}froward.svg`}
                alt="next"
              />
            </span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
