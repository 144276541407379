import React, { useContext, useState } from "react";
import Spinner from "../common/Spinner";
import { AppContext } from "../common/AppContext";
import { postformData } from "../helpers/helper";
import { toast } from "react-toastify";
import { GlobalContext } from "../../App";

function NewsLetter() {
  const [newLetterEmail, setNewLetterEmail] = useState("");
  const { setShowLoader, showLoader } = useContext(GlobalContext);

  /**
   * @method [onSaveNewsLatter] use to save the profile details
   */
  const onSaveNewsLatter = async (e) => {
    e.preventDefault();
    setShowLoader(true);

    const params = {
      email: newLetterEmail,
    };

    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}kSubscribe`,
      await postformData(params)
    );

    if (res.status >= 200 && res.status <= 399) {
      const data = await res.json();
      setNewLetterEmail("");
      toast.success("Thank you for subscribing.", { autoClose: 2000 });
      setShowLoader(false);
    }
    if (res.status === 401) {
      console.log(res, "ress");
    }
    if (res.status >= 400 && res.status <= 500) {
      const error = await res.json();
      console.log(error, "error");
      toast.error("You have already subscribed with this email", {
        autoClose: 2000,
      });
      setShowLoader(false);
    }
  };

  return (
    <>
      <div className="news-letter">
        <form onSubmit={onSaveNewsLatter}>
          <div class="template">
            <div class="logoN">
              <img
                src={
                  process.env.REACT_APP_BASE_URL ===
                  "http://103.149.154.53/WTDUSVI/api/"
                    ? `assets/images/inlogo.svg`
                    : `../assets/images/inlogo.svg`
                }
                alt="newsletter"
              />
            </div>
            <p>
              Subscribe to our weekly newsletter to stay up to date on what's
              happening in the USVI!<br></br>
              Weekly events directly to your inbox!"
            </p>
            <div class="Subscribe">
              <input
                type="email"
                placeholder="Email Address"
                value={newLetterEmail}
                onChange={(e) => setNewLetterEmail(e.target.value)}
              />
              <button class="click-btn">SUBSCRIBE</button>
            </div>
          </div>
        </form>
      </div>
      {/* <div className="news-letter">
        <img src={`${process.env.REACT_APP_LOCAL_IMG}newsL.png`} alt="" />
        <form onSubmit={onSaveNewsLatter}>
          <div className="form-gropu">
            <input
              type="text"
              placeholder="Email Address"
              value={newLetterEmail}
              onChange={(e) => setNewLetterEmail(e.target.value)}
            />
            <button className="sign-up">SUBSCRIBE</button>
          </div>
        </form>
        {showLoader && <Spinner />}
      </div> */}
    </>
  );
}

export default NewsLetter;
