import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <section className="footer">
        <div className="container">
          <div className="footer-menu">
            <img src={`${process.env.REACT_APP_LOCAL_IMG}support.svg`} alt="" />
            {/* <Link to="#">Phone: +123 00 456789</Link> */}
            <a href={`mailto:info@wtdusvi.com?Subject=Contact us`}>
              Contact us: info@wtdusvi.com
            </a>
            <a href={`mailto:info@wtdusvi.com?Subject=Advertise`}>
              Advertise: info@wtdusvi.com
            </a>
            <a href={`mailto:info@wtdusvi.com?Subject=Tells us about an event`}>
              Tells us about an event: info@wtdusvi.com
            </a>
            {/* <Link to="#">Contact us: info@yourwebsite.com</Link>
            <Link to="#">Advertise: info@yourwebsite.com</Link>
            <Link to="#">Tells us about an event: info@yourwebsite.com</Link> */}

            {/* <Link to="#">Press: press@yourwebsite.com</Link> */}
          </div>
          {/* <div className="footer-menu">
            <h3>Eventtally</h3>
            <Link to="#">Events Calendar</Link>
            <Link to="#">FAQ’s</Link>
            <Link to="#">Privacy Policy</Link>
            <Link to="#">Careers</Link>
          </div>
          <div className="footer-menu">
            <h3>Top Destinations</h3>
            <Link to="#">London</Link>
            <Link to="#">New York</Link>
            <Link to="#">Berlin</Link>
          </div>
          <div className="footer-menu">
            <h3>Others</h3>
            <Link to="#">Events</Link>
            <Link to="#">Spain</Link>
            <Link to="#">Contact</Link>
            <Link to="#">Dashboard</Link>
          </div> */}
        </div>
      </section>
    </>
  );
}

export default Footer;
